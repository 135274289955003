/**
 * This file is responsible for providing query parsing and econding functions.
 * This is to provide a standard query serialization strategy that is shared and identical to the backend
 */

import qs from 'qs';

export const stringifyQuery = (parameters, additionalOptions = {}) => (
  qs.stringify(parameters, { arrayFormat: 'indices', ...additionalOptions })
);

export const parseQuery = (url, additionalOptions = {}) => (
  qs.parse(url, { ignoreQueryPrefix: true, ...additionalOptions })
);
