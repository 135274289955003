import { defineMessages } from 'react-intl';

// eslint-disable-next-line import/prefer-default-export
export const PROVINCE_MESSAGES_BY_COUNTRY = {
  US: defineMessages({
    AL: { defaultMessage: 'Alabama' },
    AK: { defaultMessage: 'Alaska' },
    AZ: { defaultMessage: 'Arizona' },
    AR: { defaultMessage: 'Arkansas' },
    CA: { defaultMessage: 'California' },
    CO: { defaultMessage: 'Colorado' },
    CT: { defaultMessage: 'Connecticut' },
    DE: { defaultMessage: 'Delaware' },
    DC: { defaultMessage: 'District of Columbia' },
    FL: { defaultMessage: 'Florida' },
    GA: { defaultMessage: 'Georgia' },
    HI: { defaultMessage: 'Hawaii' },
    ID: { defaultMessage: 'Idaho' },
    IL: { defaultMessage: 'Illinois' },
    IN: { defaultMessage: 'Indiana' },
    IA: { defaultMessage: 'Iowa' },
    KS: { defaultMessage: 'Kansas' },
    KY: { defaultMessage: 'Kentucky' },
    LA: { defaultMessage: 'Louisiana' },
    ME: { defaultMessage: 'Maine' },
    MD: { defaultMessage: 'Maryland' },
    MA: { defaultMessage: 'Massachusetts' },
    MI: { defaultMessage: 'Michigan' },
    MN: { defaultMessage: 'Minnesota' },
    MS: { defaultMessage: 'Mississippi' },
    MO: { defaultMessage: 'Missouri' },
    MT: { defaultMessage: 'Montana' },
    NE: { defaultMessage: 'Nebraska' },
    NV: { defaultMessage: 'Nevada' },
    NH: { defaultMessage: 'New Hampshire' },
    NJ: { defaultMessage: 'New Jersey' },
    NM: { defaultMessage: 'New Mexico' },
    NY: { defaultMessage: 'New York' },
    NC: { defaultMessage: 'North Carolina' },
    ND: { defaultMessage: 'North Dakota' },
    OH: { defaultMessage: 'Ohio' },
    OK: { defaultMessage: 'Oklahoma' },
    OR: { defaultMessage: 'Oregon' },
    PA: { defaultMessage: 'Pennsylvania' },
    RI: { defaultMessage: 'Rhode Island' },
    SC: { defaultMessage: 'South Carolina' },
    SD: { defaultMessage: 'South Dakota' },
    TN: { defaultMessage: 'Tennessee' },
    TX: { defaultMessage: 'Texas' },
    UT: { defaultMessage: 'Utah' },
    VT: { defaultMessage: 'Vermont' },
    VA: { defaultMessage: 'Virginia' },
    WA: { defaultMessage: 'Washington' },
    WV: { defaultMessage: 'West Virginia' },
    WI: { defaultMessage: 'Wisconsin' },
    WY: { defaultMessage: 'Wyoming' },
  }),

  CA: defineMessages({
    AB: { defaultMessage: 'Alberta' },
    BC: { defaultMessage: 'British Columbia' },
    MB: { defaultMessage: 'Manitoba' },
    NB: { defaultMessage: 'New Brunswick' },
    NL: { defaultMessage: 'Newfoundland and Labrador' },
    NS: { defaultMessage: 'Nova Scotia' },
    ON: { defaultMessage: 'Ontario' },
    PE: { defaultMessage: 'Prince Edward Island' },
    QC: { defaultMessage: 'Quebec' },
    SK: { defaultMessage: 'Saskatchewan' },
    NT: { defaultMessage: 'Northwest Territories' },
    NU: { defaultMessage: 'Nunavut' },
    YT: { defaultMessage: 'Yukon' },
  }),
};
