import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Result, Button } from 'antd';
import Icon from '@ant-design/icons';
import history from '../../../utils/history';
import tent from '../../../icons/errors/tent.svg';

const ApplicationError = () => (
  <Result
    icon={<Icon component={() => (<img src={tent} alt="app error" />)} />}
    title={<FormattedMessage defaultMessage="Something went wrong" />}
    subTitle={<FormattedMessage defaultMessage="Oops, we've encountered an error" />}
    extra={<Button type="primary" onClick={() => history.push('/')}><FormattedMessage defaultMessage="Go Home" /></Button>}
  />
);

export default ApplicationError;
