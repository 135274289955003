import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const SEO = (props) => {
  const intl = useIntl();
  const {
    title,
    path,
    description = intl.formatMessage({ defaultMessage: 'A web based reservation software built to help campers find campgrounds, and campgrounds, RV parks, and cabin rentals manage their properties and take online bookings.' }),
    image = 'https://res.cloudinary.com/letscamp/image/upload/v1660925733/Email%20Content/logo-black.png',
  } = props;

  const url = `${window.location.origin}${path || window.location.pathname}`;

  const siteTitle = `${title ? `${title} | ` : ''}Let's Camp`;

  return (
    <Helmet>
      <title>{siteTitle}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <link rel="canonical" href={url} />

      {/* Social Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

export default SEO;
