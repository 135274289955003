import React, { useCallback, useMemo } from 'react';
import { Select as AntdSelect, Row, Col } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { errorProps } from '../../../../utils/errors';
import Form from '../Form';
import Label from './Label';
import style from './Input.module.less';

const SELECT_OPTIONS = {
  JANUARY: {
    value: 1,
    days: 31,
    message: <FormattedMessage defaultMessage="January" />,
  },
  FEBRUARY: {
    value: 2,
    days: 28,
    message: <FormattedMessage defaultMessage="February" />,
  },
  MARCH: {
    value: 3,
    days: 31,
    message: <FormattedMessage defaultMessage="March" />,
  },
  APRIL: {
    value: 4,
    days: 30,
    message: <FormattedMessage defaultMessage="April" />,
  },
  MAY: {
    value: 5,
    days: 31,
    message: <FormattedMessage defaultMessage="May" />,
  },
  JUNE: {
    value: 6,
    days: 30,
    message: <FormattedMessage defaultMessage="June" />,
  },
  JULY: {
    value: 7,
    days: 31,
    message: <FormattedMessage defaultMessage="July" />,
  },
  AUGUST: {
    value: 8,
    days: 31,
    message: <FormattedMessage defaultMessage="August" />,
  },
  SEPTEMBER: {
    value: 9,
    days: 30,
    message: <FormattedMessage defaultMessage="September" />,
  },
  OCTOBER: {
    value: 10,
    days: 31,
    message: <FormattedMessage defaultMessage="October" />,
  },
  November: {
    value: 11,
    days: 30,
    message: <FormattedMessage defaultMessage="November" />,
  },
  DECEMBER: {
    value: 12,
    days: 31,
    message: <FormattedMessage defaultMessage="December" />,
  },
};

const MonthDayPicker = (props) => {
  const {
    monthName,
    dayName,
    monthValue,
    dayValue,
    errors,
    onChange,
    tooltip,
    label,
    additionalText,
    className,
    disabled,
    ...otherProps
  } = props;

  const intl = useIntl();

  const handleMonthChange = useCallback((newValue) => {
    onChange(dayName, undefined);
    onChange(monthName, newValue);
  }, [dayName, monthName, onChange]);

  const handleDayChange = useCallback((newValue) => {
    onChange(dayName, newValue);
  }, [dayName, onChange]);

  const dayOptions = useMemo(() => {
    if (!monthValue) { return []; }

    const monthData = Object.values(SELECT_OPTIONS).find((month) => month.value === monthValue);

    if (!monthData) { return []; }

    return [...Array(monthData.days)].map((_, index) => {
      const dayVal = index + 1;
      return (<AntdSelect.Option value={dayVal} key={dayVal}>{dayVal}</AntdSelect.Option>);
    });
  }, [monthValue]);

  return (
    <Form.Item
      label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
      className={className}
      {...errorProps(errors, dayName)}
      {...errorProps(errors, monthName)}
    >
      {
        additionalText ? (
          <div className={style.additionalText}>{additionalText}</div>
        ) : null
      }
      <Row>
        <Col xs={12}>
          <AntdSelect
            name={monthName}
            disabled={disabled}
            // Note: Placeholder will only appear if value is undefined
            value={monthValue === null ? undefined : monthValue}
            onChange={handleMonthChange}
            placeholder={intl.formatMessage({ defaultMessage: 'Month' })}
            {...otherProps}
          >
            {
              Object.values(SELECT_OPTIONS).map((month) => (
                <AntdSelect.Option value={month.value} key={month.value}>
                  {month.message}
                </AntdSelect.Option>
              ))
            }
          </AntdSelect>
        </Col>
        <Col xs={12}>
          <AntdSelect
            name={dayName}
            disabled={disabled || !monthValue}
            // Note: Placeholder will only appear if value is undefined
            value={dayValue === null ? undefined : dayValue}
            onChange={handleDayChange}
            placeholder={intl.formatMessage({ defaultMessage: 'Day' })}
            {...otherProps}
          >
            {
              dayOptions
            }
          </AntdSelect>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default MonthDayPicker;
