import React, { useMemo } from 'react';
import { setMonth, setDate, setYear } from 'date-fns';
import { NO_YEAR_DATE_FORMAT } from '../../../../config/constants/constants';
import { formatDate } from '../../../../utils/format';

const MonthDay = ({ month, day }) => {
  const date = useMemo(() => {
    let displayDate = new Date();
    displayDate = setMonth(displayDate, month - 1);
    displayDate = setDate(displayDate, day);
    displayDate = setYear(displayDate, 0);
    return displayDate;
  }, [month, day]);

  return (
    <>
      {formatDate(date, NO_YEAR_DATE_FORMAT)}
    </>
  );
};

export default MonthDay;
