import { createContext } from 'react';
import { createContextualCan, useAbility as useCaslAbility } from '@casl/react';

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);
/**
 * Hook that acts as a shortcut so that AbilityContext doesn't need to be imported every time
 */
export const useAbility = () => (
  useCaslAbility(AbilityContext)
);
