import React from 'react';
import { parse, format as formatDate } from 'date-fns';
import { Form } from 'antd';
import AntdTimePicker from './util/OverriddenTimePicker';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';
import { TIME_PARSE_FORMAT } from '../../../../config/constants/constants';

const TimePicker = (props) => {
  const {
    name,
    value,
    label,
    placeholder,
    errors,
    onChange,
    required,
    tooltip,
    format = 'h:mm a',
    style,
    ...otherProps
  } = props;

  const handleChange = (time) => {
    const newValue = time ? formatDate(time, (TIME_PARSE_FORMAT)) : '';
    onChange(name, newValue);
  };

  let displayValue = value || undefined;
  if (typeof displayValue === 'string') {
    displayValue = parse(displayValue, TIME_PARSE_FORMAT, 0);
  }

  return (
    <Form.Item
      required={required}
      label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
      {...errorProps(errors, name)}
    >
      <AntdTimePicker
        name={name}
        format={format}
        placeholder={placeholder || label}
        value={displayValue}
        onChange={handleChange}
        onSelect={handleChange}
        style={{ width: '200px', maxWidth: '100%', ...style }}
        {...otherProps}
      />
    </Form.Item>
  );
};

export default TimePicker;
