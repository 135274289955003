/**
 * This file acts as a singleton to store the router history of the application.
 * This object is used in the router context so it can be referenced in any file and still work correctly
 */

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;

/**
 * This function will take `history.location.pathname` and split it
 * into its individual parts. Ex: `/client/users/1` becomes `['client', 'users', '1']`
 */
export const locationParts = () => (
  // Regex removes `/` from the start and end of the string
  history.location.pathname.replace(/(^\/)|(\/$)/g, '').split('/')
);
