/**
 * This file is responsible for configuring Casl to properly check permissions.
 * If a string is given we use that as the subject name,
 * if not the typename variable which should be included in the backend mapper
 */

import { AbilityBuilder } from '@casl/ability';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }

  // eslint-disable-next-line no-underscore-dangle
  return item.__typename;
}

export default AbilityBuilder.define({ subjectName }, () => {});
