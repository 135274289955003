import React from 'react';
import { DATETIME_FORMAT, DATETIME_TZ_FORMAT, DATETIME_UTC_FORMAT } from '../../../../config/constants/constants';
import { formatDateTime } from '../../../../utils/format';

const DateTime = ({
  dateTime, dateFormat, showTimeZone = false, useUTC = false,
}) => (
  <>
    {
        formatDateTime(
          dateTime,
          useUTC
            ? DATETIME_UTC_FORMAT
            : dateFormat || (showTimeZone ? DATETIME_TZ_FORMAT : DATETIME_FORMAT),
        )
    }
  </>
);

export default DateTime;
