import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import { parseISO } from 'date-fns';
import AntdDatePicker from './util/OverriddenDatePicker';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date, dateString) {
    const { name, onChange } = this.props;
    onChange(name, dateString);
  }

  render() {
    const {
      name,
      value,
      label,
      placeholder,
      errors,
      onChange,
      intl,
      required,
      tooltip,
      ...otherProps
    } = this.props;

    let displayDate = value || undefined;
    if (typeof displayDate === 'string') {
      displayDate = parseISO(displayDate);
    }

    return (
      <Form.Item
        required={required}
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        {...errorProps(errors, name)}
      >
        <AntdDatePicker
          name={name}
          placeholder={
            placeholder || label
          }
          value={displayDate}
          onChange={this.handleChange}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

export default injectIntl(DatePicker);
