/**
 * This file is responsible for giving an abstraction to the application to render markdown content.
 */

import MarkdownIt from 'markdown-it';
import { DEFAULT_MARKDOWN_FORMATTING_OPTIONS } from '../config/constants/constants';

export default function (formattingOptions = DEFAULT_MARKDOWN_FORMATTING_OPTIONS) {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  const flattenedOptions = formattingOptions.flat(3);

  md.disable('hr');

  if (!flattenedOptions.includes('header')) {
    md.disable('heading');
  }
  if (!flattenedOptions.includes('bold') && !flattenedOptions.includes('italic')) {
    md.disable('emphasis');
  }
  if (!flattenedOptions.includes('strikethrough')) {
    md.disable('strikethrough');
  }
  if (!flattenedOptions.includes('link')) {
    md.disable('link');
    md.disable('autolink');
    md.disable('linkify');
  }
  if (!flattenedOptions.includes('quote')) {
    md.disable('blockquote');
  }
  if (!flattenedOptions.includes('image')) {
    md.disable('image');
  }
  if (!flattenedOptions.includes('unordered-list') && !flattenedOptions.includes('ordered-list')) {
    md.disable('list');
  }
  if (!flattenedOptions.includes('code')) {
    md.disable('fence');
    md.disable('backticks');
  }

  return md;
}
