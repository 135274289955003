import React from 'react';
import { DATE_FORMAT } from '../../../../config/constants/constants';
import { formatDate } from '../../../../utils/format';

const Date = ({ date, dateFormat }) => (
  <>
    {formatDate(date, dateFormat || DATE_FORMAT)}
  </>
);

export default Date;
