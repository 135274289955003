import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import Loading from './Loading';

const AuthenticatedRoute = (props) => {
  const userContext = useContext(UserContext);

  if (!userContext.loaded) {
    return <Loading />;
  }

  if (!userContext.user) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};

export default AuthenticatedRoute;
