import React from 'react';
import {
  Col,
} from 'antd';
import Label from '../Label';
import style from './LabelColumn.module.less';

const LabelColumn = (props) => {
  const { label, tooltip, additionalInfo } = props;

  return (
    <Col>
      <div className={style.label}>
        <Label text={label} tooltip={tooltip} />
      </div>
      {
        additionalInfo ? (
          <div className={style.additionalInfo}>
            { additionalInfo }
          </div>
        ) : null
      }
    </Col>
  );
};

export default LabelColumn;
