import React, { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import UserContext from './bundles/common/contexts/UserContext';
import CookieConsentContext from './bundles/common/contexts/CookieConsentContext';

const Scripts = () => {
  const userContext = useContext(UserContext);
  const { cookieConsent } = useContext(CookieConsentContext);
  const loadedPendo = useRef(false);

  useEffect(() => {
    if (!loadedPendo.current) {
      if (userContext.loaded && window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: userContext.user ? userContext.user._id : undefined,
          },
        });
        loadedPendo.current = true;
      }
    }
  }, [userContext]);

  if (cookieConsent?.analyticalConsent) {
    return (
      <Helmet>
        {/* Hotjar Tracking Code */}
        <script>
          {
            `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${window.ENV.REACT_APP_HOTJAR_SITE_ID},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `
          }
        </script>
        {/* Pendo Code */}
        <script>
          {
            `
              (function(apiKey){
                (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
                v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                    o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                    y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
                    z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
              })('6f48ce91-2d08-41d6-7c69-fc675d7a5585');
            `
          }
        </script>
        {/* Google Tag Manager */}
        <script>
          {
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5BH3V8S');`
          }
        </script>
      </Helmet>
    );
  }
  return null;
};

export default Scripts;
