import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from './Select';
import Switch from './Switch';
import {
  AMP_LEVELS,
} from '../../../../config/constants/site';

const { Option } = Select;

const ElectricalSelect = (props) => {
  const {
    name,
    value,
    onChange,
    label,
    errors,
    required,
    tooltip,
  } = props;

  const intl = useIntl();

  const [electrical, setElectrical] = useState(value && value.length);

  const handleElectricalChange = () => {
    const newElectrical = !electrical;
    setElectrical(newElectrical);
    if (!newElectrical) {
      onChange(name, null);
    }
  };

  useEffect(() => {
    setElectrical(value && value.length);
  }, [value]);

  return (
    <>
      <Switch
        name="electricalService"
        label={label}
        onChange={handleElectricalChange}
        value={electrical}
        tooltip={tooltip}
      />
      {
        electrical ? (
          <Select
            name={name}
            mode="multiple"
            value={value}
            errors={errors}
            onChange={onChange}
            required={required}
            placeholder={intl.formatMessage({ defaultMessage: 'Available Amperages' })}
          >
            {
              AMP_LEVELS.map(({ value: val, translation }) => (
                <Option
                  value={val}
                  key={val}
                >
                  <FormattedMessage {...translation} />
                </Option>
              ))
            }
          </Select>
        ) : null
      }
    </>
  );
};

export default ElectricalSelect;
