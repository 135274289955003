import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { Form, Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';
import withImageUploadProps from './util/withImageUploadProps';

class ImageUploader extends Component {
  constructor(props) {
    super(props);

    // Build original file list from the values passed in
    const { image = {}, value } = props;

    let file;
    if (value) {
      file = {
        uid: value,
        _id: value,
        status: 'done',
        url: image._id === value ? image.sizes.thumb : undefined,
        full: image._id === value ? image.url : undefined,
      };
    }

    this.state = {
      // Stores the state of the uploaded image
      file,
      previewVisible: false,
    };

    this.handleUploadingListChange = this.handleUploadingListChange.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onPreviewClose = this.onPreviewClose.bind(this);
  }

  /**
   * Updates `fileList` state variable based on the changed id in the `value` prop
   */
  componentDidUpdate(prevProps) {
    const { image, value } = this.props;
    const { file } = this.state;

    const valueChanged = value !== prevProps.value;

    if (valueChanged && value && file._id !== value) {
      const providedImage = image || {};
      const newFile = {
        uid: value,
        _id: value,
        status: 'done',
        url: providedImage._id === value ? providedImage.sizes.thumb : undefined,
        full: providedImage._id === value ? providedImage.url : undefined,
      };

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ file: newFile });
    }
  }

  onPreview() {
    this.setState({
      previewVisible: true,
    });
  }

  onPreviewClose() {
    this.setState({
      previewVisible: false,
    });
  }

  /**
   * updates the `fileList` state variable with the current state of images
   */
  handleUploadingListChange({ file }) {
    const { onChange, name } = this.props;

    let newFile;
    let newValue = null;

    if (file.status === 'uploading' || file.status === 'done') {
      newFile = { ...file };

      // If the file was uploaded in the current scope, set id and url
      if (newFile.response && !newFile._id && !newFile.url && !newFile.full) {
        const { image } = newFile.response;
        newFile._id = image._id;
        newFile.url = image.sizes.thumb;
        newFile.full = image.url;
      }

      newValue = newFile._id;
    }

    this.setState({ file: newFile });

    if (onChange) {
      onChange(name, newValue);
    }
  }

  render() {
    const {
      name,
      textArea,
      value,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      route,
      customRequest,
      beforeUpload,
      error,
      ...otherProps
    } = this.props;

    const {
      file,
      previewVisible,
    } = this.state;

    return (
      <>
        <Form.Item
          label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
          required={required}
          {...errorProps(errors, name)}
          {...(error ? { validateStatus: 'error', help: error } : (errorProps(errors, name)))}
        >
          <Upload
            listType="picture-card"
            action={route}
            beforeUpload={beforeUpload}
            fileList={file ? [file] : []}
            onChange={this.handleUploadingListChange}
            customRequest={customRequest}
            onPreview={this.onPreview}
            {...otherProps}
          >
            {
              !file ? (
                <div>
                  <PlusOutlined />
                  <div className="ant-upload-text">
                    <FormattedMessage defaultMessage="Upload" />
                  </div>
                </div>
              ) : null
            }
          </Upload>
        </Form.Item>

        <Modal
          visible={previewVisible}
          footer={null}
          bodyStyle={{ padding: 0 }}
          onCancel={this.onPreviewClose}
        >
          <img alt="example" style={{ width: '100%' }} src={file ? file.full : ''} />
        </Modal>
      </>
    );
  }
}

export default withImageUploadProps(ImageUploader);
