import React from 'react';
import { Redirect } from 'react-router';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return <Redirect to="/error" />;
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorHandler;
