import React from 'react';
import InfoTooltip from '../InfoTooltip';

const Label = (props) => {
  const { text, tooltip } = props;
  return (
    <>
      {text}
      {
        tooltip ? (
          <InfoTooltip message={tooltip} />
        ) : null
      }
    </>
  );
};

export default Label;
