import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CookieConsent from './CookieConsent';
import CookieConsentModal from './CookieConsentModal';
import style from './Footer.module.less';

const SubFooter = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Row align="middle">
      <Col lg={8} xs={24} className={style.copyright}>
        <FormattedMessage defaultMessage={"\u00A9 {year} Let's Camp All Rights Reserved"} values={{ year: new Date().getFullYear() }} />
      </Col>

      <Col lg={16} xs={24} className={style.termsPolicies}>
        <span className={style.termsPoliciesLink}>
          <Link to="/terms-conditions">
            <FormattedMessage defaultMessage="Terms of Use & Privacy Policy" />
          </Link>
        </span>
        <span className={style.termsPoliciesLink}>
          <Button onClick={() => setShowModal(true)} type="link"><FormattedMessage defaultMessage="Cookie Preferences" /></Button>
        </span>
        <a href="https://www.yastech.ca/" title="Campground Web Design" target="_blank" rel="noopener noreferrer">
          <img id="yastechLogo" src="https://s3.amazonaws.com/cdn.yastech.ca/marketing/footers/yastech.png" alt="Yas Web Design Logo" width="75" />
        </a>
      </Col>
      <CookieConsent setShowModal={setShowModal} />
      <CookieConsentModal showModal={showModal} setShowModal={setShowModal} />
    </Row>
  );
};

export default SubFooter;
