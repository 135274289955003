import React, { Component } from 'react';
import axios from 'axios';
import ability from '../../../utils/ability';
import history, { locationParts } from '../../../utils/history';
import { impersonate, isImpersonating, stopImpersonating } from '../../../utils/impersonateClient';
import {
  clearActiveCamp, getActiveCampId, getActiveCampSlug, setActiveCamp, getLastUsedCampId,
} from '../utils/activeCamp';
import getCampSlug from '../../../utils/getCampSlug';
import { parseQuery } from '../../../utils/queryString';

const ClientContext = React.createContext();

const setCampRules = (camp) => {
  // Set the rules to the clients rules, if the server returns them
  // Specify these rules as temporary (they came from impersonating)
  if (isImpersonating()) {
    ability.temporary = true;
  }
  ability.update(camp.rules);
};

/**
 * This function will take the old camp url, and insert the new camp Id,
 * to try to take the user back to the same location on the new camp.
 *
 * Because the old URL can potentially contain IDs for resource that are not
 * available on the new camp, the second objectId found, and anything after it will
 * be removed. Ex: `/client/123/sites/321/` will become `/client/789/sites`
 *
 * @param {*} url The URL to replace
 * @param {*} oldCamp the old camp object
 * @param {*} newCamp the new camp object
 */
export const swapCampUrl = (url, oldCamp, newCamp) => {
  if (!newCamp) {
    return '/client';
  }
  const oldCampSlug = getCampSlug(oldCamp);
  const newCampSlug = getCampSlug(newCamp);

  // Replace old camp ID with new camp ID
  let newUrl = url.replace(oldCampSlug, newCampSlug);
  // Find chop off any other routes after the next objectId,
  // or after the new camp's slug
  // as that object will not exist on the new camp

  // Replace special chars (specifically hyphens) from the slug
  // Note: lodash's escapeRegExp function does not escape hyphens for some reason,
  // so we need to do this manually
  // https://github.com/lodash/lodash/issues/2573
  const escapedSlug = newCampSlug.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regExp = new RegExp(`(^.*([0-9A-Fa-f]{24}|${escapedSlug}).*)(/[0-9A-Fa-f]{24}.*)`);
  newUrl = newUrl.replace(regExp, '$1');

  return newUrl;
};

/**
 * This class serves as a wrapper for the ClientContext.Provider.
 */
export class ClientProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: null,
      camp: null,
    };

    this.switchCamp = this.switchCamp.bind(this);
  }

  componentDidMount() {
    // If we are not currently impersonating, start impersonating if there is an ID in the URL
    if (!isImpersonating()) {
      const { impersonating: clientId } = parseQuery(history.location.search);
      if (clientId) {
        impersonate(clientId);
      }
    }

    // Load campId from the URL, in case the user was directly linked to this camp
    const campIdOrSlug = locationParts()[1];
    let campSlug;
    let campId;
    // Otherwise load if one is already set
    if (!campIdOrSlug) {
      campId = getActiveCampId();
      campSlug = getActiveCampSlug();
      // Remove before we re-set it in case no camp is returned
      clearActiveCamp();
    }

    // Retrieve the client's information
    axios.get('/client/profile')
      .then((response) => {
        if (response && response.data) {
          const { client } = response.data;
          let camp;
          // Load with the specified campId
          if (campIdOrSlug) {
            camp = client.camps.find(
              (clientCamp) => (clientCamp.info && clientCamp.info.slug === campIdOrSlug)
                || clientCamp._id === campIdOrSlug,
            );
          } else if (campSlug) {
            camp = client.camps.find(
              (clientCamp) => clientCamp.info && clientCamp.info.slug === campSlug,
            );
          } else if (campId) {
            camp = client.camps.find((clientCamp) => clientCamp._id === campIdOrSlug);
          }
          /*
           * If the camp wasn't loaded default to either the last used campground,
           * or the first campground if there isn't a last used
           */
          if (!camp) {
            const lastUsedCampId = getLastUsedCampId();
            if (lastUsedCampId) {
              camp = client.camps.find((curCamp) => curCamp._id === lastUsedCampId);
              // Unset the camp if the user can't read it
              if (!ability.can('read', camp)) {
                camp = null;
              }
            }
            // Fallback for if the lastUsedCampId does not exist in the list of camps
            if (!camp) {
              // Find the first camp in the client's list which the user can read
              camp = client.camps.find((campToCheck) => ability.can('read', campToCheck));
            }
          }

          // Check that the camp exists, in the very extreme case that a client doesn't have a camp
          // or if the user cannot read any camps in the client
          if (camp) {
            campSlug = camp.info && camp.info.slug ? camp.info.slug : null;
            campId = camp._id;
            setCampRules(camp);
            setActiveCamp(campId, campSlug);
            // Only redirect if the user did not go directly to this camp
            if (!history.location.pathname.match(`/client/${campSlug || campId}`)) {
              history.replace(`/client/${campSlug || campId}`);
            }
            this.setState({ client, camp });
          }
        }
      }).catch(() => {});
  }

  componentWillUnmount() {
    /*
     * In the case that the user is impersonating the client,
     * remove currently stored impersonation when they leave the client portal
     */
    stopImpersonating();

    // Restore old rules (if they were overridden)
    if (ability.temporary) {
      ability.update(ability.originalRules);
      delete ability.temporary;
    }
  }

  switchCamp(id) {
    const { client, camp } = this.state;
    const newCamp = client.camps.find((clientCamp) => clientCamp._id === id);
    this.setState({ camp: newCamp });
    if (newCamp) {
      setCampRules(newCamp);
      setActiveCamp(newCamp._id, newCamp.info?.slug);
    }
    // Redirect to the new camp
    history.replace(swapCampUrl(history.location.pathname, camp, newCamp));
  }

  render() {
    const { children } = this.props;
    const { client, camp } = this.state;
    return (
      <ClientContext.Provider
        value={
          {
            client,
            camp,
            switchCamp: this.switchCamp,
          }
        }
      >
        {children}
      </ClientContext.Provider>
    );
  }
}

export const ClientConsumer = ClientContext.Consumer;

/**
 * A function to create an HOC component that is a consumer of the AlertContext
 *
 * This will cause the component to have a property of `client` and `camp` passed in,
 * so any component can get access to the current client,
 * and current camp while on the client portal.
 * Also providers the `switchCamp` method for swapping campgrounds if there are more than 1
 * This can be used to determine which camp's data to fetch, and to send requests to
 *
 * @param {*} ChildComponent The component to wrap
 */
export const withClient = (ChildComponent) => (
  function WrapperComponent(props) {
    return (
      // Render the context consumer
      // And render the child component with its usual props, as well as the client props
      <ClientContext.Consumer>
        {
          (value) => (
            <ChildComponent
              {...props}
              camp={value.camp}
              client={value.client}
              switchCamp={value.switchCamp}
            />
          )
        }
      </ClientContext.Consumer>
    );
  }
);

export default ClientContext;
