import React, { Component } from 'react';
import { Input as AntdInput } from 'antd';

class HiddenInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // Handle change for text input
  handleChange(event) {
    const { name, value } = event.target;
    const { onChange } = this.props;

    onChange(name, value);
  }

  render() {
    const {
      name,
      value,
      onChange,
      ...otherProps
    } = this.props;

    return (
      <div
        style={
          {
            visibility: '0',
            margin: '0',
            height: '0px',
            overflow: 'hidden',
          }
        }
      >
        <AntdInput
          name={name}
          value={value || undefined}
          onChange={this.handleChange}
          {...otherProps}
        />
      </div>
    );
  }
}

export default HiddenInput;
