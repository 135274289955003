/**
 * This file is responsible for retrieving the history stack stored in localStorage. Utilized by HistoryStackContext
 */

/**
 * Retrieve the stored locations from session storage
 * @returns An array of stored locations
 */
const getStoredLocations = () => {
  const stored = sessionStorage.getItem('locations');
  if (!stored) {
    return [];
  }

  const locations = JSON.parse(stored);

  return locations;
};

export default getStoredLocations;
