import React, { Component } from 'react';
import { Radio as AntdRadio, Form } from 'antd';
import style from './RadioGroup.module.less';
import { errorProps } from '../../../../utils/errors';

class RadioGroup extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // Handle change for text input
  handleChange(event) {
    const { name, realvalue } = event.target;
    const { onChange } = this.props;
    const value = realvalue;
    onChange(name, value);
  }

  render() {
    const {
      name,
      value,
      label,
      errors,
      onChange,
      children,
      options,
      buttons = false,
      ...otherProps
    } = this.props;

    let RadioOption = AntdRadio;

    if (buttons) {
      RadioOption = AntdRadio.Button;
    }

    return (
      <Form.Item {...errorProps(errors, name)} className={style.radioGroup}>
        <AntdRadio.Group
          name={name}
          value={value || undefined}
          onChange={this.handleChange}
          {...otherProps}
        >
          {
            options.map((element) => (
              <RadioOption
                key={element.value}
                value={element.value || undefined}
                realvalue={element.value}
              >
                {element.label}
              </RadioOption>
            ))
          }
        </AntdRadio.Group>
      </Form.Item>
    );
  }
}

export default RadioGroup;
