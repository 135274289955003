import React from 'react';
import { Form as AntdForm } from 'antd';
import SubmissionError from './SubmissionError';

/**
 * Serves as an override/wrapper to antd's form so that we can continue using handleSubmit,
 * and default all forms to be vertical.
 * @param {*} props The react props for the form
 */
const Form = (props) => {
  const { onSubmit } = props;

  const newProps = {
    ...props,
  };

  if (onSubmit) {
    newProps.onFinish = () => onSubmit();
    delete newProps.onSubmit;
  }

  return <AntdForm layout="vertical" {...newProps} />;
};

Form.Item = AntdForm.Item;

Form.SubmissionError = SubmissionError;

export default Form;
