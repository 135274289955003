/**
 * This file is responsible for defining an abstraction to use when a let's camp admin needs to impersonate a campground user
 */

export const impersonate = (clientId) => {
  sessionStorage.setItem('impersonating', clientId);
};

export const stopImpersonating = () => {
  sessionStorage.removeItem('impersonating');
};

export const getImpersonatedId = () => (
  sessionStorage.getItem('impersonating')
);

export const isImpersonating = () => (
  Boolean(getImpersonatedId())
);
