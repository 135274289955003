let activeCampId = null;
let activeCampSlug = null;

export const setLastUsedCampId = (campId) => {
  if (!campId) {
    localStorage.removeItem('lastUsedCampId');
  } else {
    localStorage.setItem('lastUsedCampId', campId);
  }
};

export const getLastUsedCampId = () => (
  localStorage.getItem('lastUsedCampId')
);

export const getActiveCampId = () => (
  activeCampId
);

export const getActiveCampSlug = () => (
  activeCampSlug
);

export const setActiveCamp = (campId, slug = null) => {
  activeCampId = campId;
  activeCampSlug = slug;

  setLastUsedCampId(activeCampId);
};

export const clearActiveCamp = () => {
  activeCampId = null;
  activeCampSlug = null;
};
