import React, { Component } from 'react';
import { parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Form } from 'antd';
import AntdDatePicker from './util/OverriddenDatePicker';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';

class DateTimePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    const { name, onChange, timeZone } = this.props;
    let newValue = date;

    if (newValue !== null) {
      if (timeZone) {
        newValue = zonedTimeToUtc(date, timeZone);
      }
      newValue = newValue.toISOString();
    }

    onChange(name, newValue);
  }

  render() {
    const {
      name,
      value,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      timeFormat = 'HH:mm',
      timeZone,
      ...otherProps
    } = this.props;

    let displayedValue = value || undefined;
    if (displayedValue) {
      if (typeof displayedValue === 'string') {
        displayedValue = parseISO(value);
      }
      if (timeZone) {
        displayedValue = utcToZonedTime(displayedValue, timeZone);
      }
    }

    return (
      <Form.Item
        required={required}
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        {...errorProps(errors, name)}
      >
        <AntdDatePicker
          name={name}
          showTime={{ format: timeFormat }}
          format={`YYYY-MM-DD ${timeFormat}`}
          placeholder={placeholder || label}
          value={displayedValue}
          onChange={this.handleChange}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

export default DateTimePicker;
