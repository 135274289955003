import { useState, useEffect } from 'react';

export default function useBreakpoints() {
  const [sm, setSm] = useState();
  const [md, setMd] = useState();
  const [lg, setLg] = useState();
  const [xl, setXl] = useState();
  const [xxl, setXxl] = useState();

  useEffect(() => {
    const setBreakpoint = (setFn) => (
      (query) => {
        setFn(query.matches);
      }
    );

    const breakpoints = [
      { query: window.matchMedia('(min-width: 576px)'), setFn: setSm },
      { query: window.matchMedia('(min-width: 768px)'), setFn: setMd },
      { query: window.matchMedia('(min-width: 992px)'), setFn: setLg },
      { query: window.matchMedia('(min-width: 1200px)'), setFn: setXl },
      { query: window.matchMedia('(min-width: 1600px)'), setFn: setXxl },
    ];

    const removeListenerOperations = [];

    breakpoints.forEach(({ query, setFn }) => {
      const handler = setBreakpoint(setFn);
      handler(query);
      query.addListener(handler);
      removeListenerOperations.push(() => { query.removeListener(handler); });
    });

    return () => {
      removeListenerOperations.forEach((operation) => {
        operation();
      });
    };
  }, []);

  return {
    xs: true,
    sm,
    md,
    lg,
    xl,
    xxl,
  };
}
