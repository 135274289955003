import React, { useCallback, useState } from 'react';
import getStoredLocations from '../../../utils/getStoredLocations';

const HistoryStackContext = React.createContext();

/**
 * This class serves as a wrapper for the HistoryContext.Provider.
 * this will provide all consumers with the history stack and the add function.
 */
export const HistoryStackProvider = ({ children }) => {
  const [historyStack, setHistoryStack] = useState(getStoredLocations());

  const add = useCallback((location) => {
    let locations = [...historyStack];
    locations.push(location);
    locations = locations.slice(-50); // Trim to only the last 50
    sessionStorage.setItem('locations', JSON.stringify(locations));
    setHistoryStack(locations);
  }, [setHistoryStack, historyStack]);

  return (
    <HistoryStackContext.Provider
      value={
        {
          stack: historyStack,
          add,
        }
      }
    >
      {children}
    </HistoryStackContext.Provider>
  );
};

export const HistoryStackConsumer = HistoryStackContext.Consumer;

export default HistoryStackContext;
