import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const InfoTooltip = ({ message }) => (
  <Tooltip title={message} placement="right">
    <QuestionCircleOutlined style={{ marginLeft: '0.5em' }} />
  </Tooltip>
);

export default InfoTooltip;
