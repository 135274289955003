import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import Select from './Select';
import {
  UNIT_TYPES, BOAT_UNIT_TYPES, SINGLE_UNIT_TYPES, NON_SINGLE_UNIT_TYPES,
} from '../../../../config/constants/booking';

const { Option } = Select;

class AvailableUnitTypesSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableUnitTypes: props.allowBoats ? BOAT_UNIT_TYPES : UNIT_TYPES,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    if (value) {
      // Update available unit types
      this.updateAvailableUnitTypes(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      // Update available unit types
      this.updateAvailableUnitTypes(false);
    }
  }

  // Run a check on available unit types
  updateAvailableUnitTypes(onMount) {
    const { value, allowBoats } = this.props;
    // This check should only have to be run onMount for edit forms
    if (onMount && value.length > 1) {
      this.setState({
        availableUnitTypes: NON_SINGLE_UNIT_TYPES,
      });
    } else if (
      value.length === 1
        && SINGLE_UNIT_TYPES.includes(value[0])
    ) {
      this.setState({
        availableUnitTypes: allowBoats ? BOAT_UNIT_TYPES.filter(
          (type) => type.value === value[0],
        ) : UNIT_TYPES.filter(
          (type) => type.value === value[0],
        ),
      });
    } else if (
      value.length === 1
        && !SINGLE_UNIT_TYPES.includes(value[0])
    ) {
      this.setState({
        availableUnitTypes: NON_SINGLE_UNIT_TYPES,
      });
    } else if (value.length === 0) {
      this.setState({ availableUnitTypes: allowBoats ? BOAT_UNIT_TYPES : UNIT_TYPES });
    }
  }

  render() {
    const {
      name,
      value,
      onChange,
      label,
      errors,
      required,
      tooltip,
      allowBoats,
    } = this.props;

    const { availableUnitTypes } = this.state;

    return (
      <Select
        name={name}
        mode="multiple"
        label={label}
        value={value}
        errors={errors}
        onChange={onChange}
        required={required}
        tooltip={tooltip}
      >
        {
          (allowBoats ? BOAT_UNIT_TYPES : UNIT_TYPES).map(({ value: val, translation }) => (
            <Option
              value={val}
              key={val}
              disabled={!availableUnitTypes.some((type) => type.value === val)}
            >
              <FormattedMessage {...translation} />
            </Option>
          ))
        }
      </Select>
    );
  }
}

export default AvailableUnitTypesSelect;
