import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'date-time-format-timezone';
import React from 'react';
import ReactDOM from 'react-dom';
import setupConfig from './config/setupConfig';
import './styles/App.less';
import App from './App';
import * as serviceWorker from './serviceWorker';

setupConfig();

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
