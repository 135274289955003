import { defineMessages } from 'react-intl';

export const SITE_PULL_THROUGH_MESSAGES = defineMessages({
  true: { defaultMessage: 'Pull-Through Site' },
  false: { defaultMessage: 'Not Pull-Through' },
});

export const SITE_ACCESSIBLE_MESSAGES = defineMessages({
  true: { defaultMessage: 'Accessible Site' },
  false: { defaultMessage: 'Non-Accessible Site' },
});

export const SITE_WATER_MESSAGES = defineMessages({
  true: { defaultMessage: 'Water Serviced' },
  false: { defaultMessage: 'No Water Service' },
});

export const SITE_SEWER_MESSAGES = defineMessages({
  true: { defaultMessage: 'Sewer Serviced' },
  false: { defaultMessage: 'No Sewer Service' },
});

export const SITE_ALLOW_PETS_MESSAGES = defineMessages({
  true: { defaultMessage: 'Pets Allowed' },
  false: { defaultMessage: 'No Pets Allowed' },
});

export const SITE_HAS_PETS_MESSAGES = defineMessages({
  true: { defaultMessage: 'Has Pets' },
  false: { defaultMessage: 'No Pets' },
});

export const SITE_ELECTRICAL_MESSAGES = defineMessages({
  true: { defaultMessage: '{amps} amps' },
  false: { defaultMessage: 'No Electrical Service' },
});

export const SITE_UPLOAD_KEY_MESSAGES = defineMessages({
  siteNumber: { defaultMessage: 'Site Number' },
  adminOnly: { defaultMessage: 'Available to Camp Admins Only' },
  description: { defaultMessage: 'Description' },
  priceModelName: { defaultMessage: 'Pricing Model' },
  priceModelId: { defaultMessage: 'Pricing Model' },
  length: { defaultMessage: 'Site Length' },
  width: { defaultMessage: 'Site Width' },
  minNights: { defaultMessage: 'Min Nights Allowed' },
  maxNights: { defaultMessage: 'Max Nights Allowed' },
  minUnits: { defaultMessage: 'Min Camping Units Allowed' },
  maxUnits: { defaultMessage: 'Max Camping Units Allowed' },
  allowedUnitTypes: { defaultMessage: 'Allowed Camping Unit Types' },
  minRvLength: { defaultMessage: 'Minimum RV Length' },
  maxRvLength: { defaultMessage: 'Maximum RV Length' },
  maxCampers: { defaultMessage: 'Max Guests Allowed' },
  pullThrough: { defaultMessage: 'Pull-Through' },
  accessible: { defaultMessage: 'Accessible Site' },
  water: { defaultMessage: 'Water Service' },
  sewer: { defaultMessage: 'Sewer Service' },
  electrical: { defaultMessage: 'Electrical Service' },
});

export const AMP_LEVELS = [
  {
    value: 15,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 15 },
    },
  },
  {
    value: 20,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 20 },
    },
  },
  {
    value: 30,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 30 },
    },
  },
  {
    value: 50,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 50 },
    },
  },
];

export const AMP_LEVELS_SEARCH = [
  {
    value: null,
    translation: { ...SITE_ELECTRICAL_MESSAGES.false },
  },
  {
    value: 15,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 15 },
    },
  },
  {
    value: 20,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 20 },
    },
  },
  {
    value: 30,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 30 },
    },
  },
  {
    value: 50,
    translation: {
      ...SITE_ELECTRICAL_MESSAGES.true,
      values: { amps: 50 },
    },
  },
];

export const SITE_FEATURES_FOR_SEARCH_MESSAGES = defineMessages({
  water: { defaultMessage: 'Sites with water' },
  nonElectric: { defaultMessage: 'No electric' },
  sewer: { defaultMessage: 'Sites with sewer hookup' },
  '15amp': { defaultMessage: 'Electric 15 amp' },
  pullThrough: { defaultMessage: 'Pull-through sites' },
  '20amp': { defaultMessage: 'Electric 20 amp' },
  '30amp': { defaultMessage: 'Electric 30 amp' },
  accessible: { defaultMessage: 'Accessible sites' },
  '50amp': { defaultMessage: 'Electric 50 amp' },
});

export const SITE_FEATURES_FOR_SEARCH = [
  {
    value: 'nonElectric',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES.nonElectric,
  },
  {
    value: 'water',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES.water,
  },
  {
    value: '15amp',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES['15amp'],
  },
  {
    value: 'sewer',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES.sewer,
  },
  {
    value: '20amp',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES['20amp'],
  },
  {
    value: 'pullThrough',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES.pullThrough,
  },
  {
    value: '30amp',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES['30amp'],
  },
  {
    value: 'accessible',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES.accessible,
  },
  {
    value: '50amp',
    translation: SITE_FEATURES_FOR_SEARCH_MESSAGES['50amp'],
  },
];

export const SITE_SEARCH_NO_SITES_FOUND = 'noSitesFound';
export const SITE_SEARCH_SITES_FOUND = 'sitesFound';
export const SITE_SEARCH_BOOKINGS_NOT_OPEN = 'bookingsNotOpen';
export const SITE_STATUS_UNDER_MIN_BOOKING_NOTICE = 'underMinBookingNotice';
export const SITE_STATUS_OVER_MAX_BOOKING_NOTICE = 'overMaxBookingNotice';
export const SITE_STATUS_LONG_WEEKEND_RULE_APPLIES = 'longWeekendRuleApplies';
export const SITE_STATUS_SEASON_ENDING = 'seasonEnding';
export const SITE_STATUS_UNDER_MIN_NIGHTS = 'underMinNights';


export const SITE_SEARCH_RESULTS_MESSAGES = defineMessages({
  sitesFound: { defaultMessage: '{numSites} available sites found' },
  noSitesFound: { defaultMessage: 'No available sites found' },
  bookingsNotOpen: { defaultMessage: 'Sites will be available for booking on {date}' },
  underMinBookingNotice: { defaultMessage: 'Sites are not available for booking less than {numDays} {numDays, plural, one {day} other {days}} in advance' },
  overMaxBookingNotice: { defaultMessage: 'Sites are only available for booking {numDays} days in advance' },
  longWeekendRuleApplies: { defaultMessage: 'Bookings between {startDate} and {endDate} must have a minimum length of {minNightsBooked} nights and must include the entire specified date range' },
  seasonEnding: { defaultMessage: 'The current season ends on {endDate}' },
  underMinNights: { defaultMessage: 'This campground is open for bookings of {minNights} nights or more.' },
});

export const SITE_SEARCH_RESULTS = {
  [SITE_SEARCH_NO_SITES_FOUND]: SITE_SEARCH_RESULTS_MESSAGES.noSitesFound,
  [SITE_SEARCH_SITES_FOUND]: SITE_SEARCH_RESULTS_MESSAGES.sitesFound,
  [SITE_SEARCH_BOOKINGS_NOT_OPEN]: SITE_SEARCH_RESULTS_MESSAGES.bookingsNotOpen,
  [SITE_STATUS_UNDER_MIN_BOOKING_NOTICE]: SITE_SEARCH_RESULTS_MESSAGES.underMinBookingNotice,
  [SITE_STATUS_OVER_MAX_BOOKING_NOTICE]: SITE_SEARCH_RESULTS_MESSAGES.overMaxBookingNotice,
  [SITE_STATUS_LONG_WEEKEND_RULE_APPLIES]: SITE_SEARCH_RESULTS_MESSAGES.longWeekendRuleApplies,
  [SITE_STATUS_SEASON_ENDING]: SITE_SEARCH_RESULTS_MESSAGES.seasonEnding,
  [SITE_STATUS_UNDER_MIN_NIGHTS]: SITE_SEARCH_RESULTS_MESSAGES.underMinNights,
};
