import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => (
  <div style={{ textAlign: 'center', paddingTop: '100px', paddingBottom: '100px' }}>
    <LoadingOutlined style={{ fontSize: '36px' }} />
  </div>
);

export default Loading;
