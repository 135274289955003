import React, { useState, useCallback } from 'react';
import {
  Layout, Typography, Button, Avatar, Divider, Menu,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  RightOutlined, LeftOutlined, UserOutlined, MenuOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import Footer from './Footer';
import { withUser } from '../contexts/UserContext';
import logo from '../../../images/logo-simple-green.png';
import style from './Portal.module.less';
import useBreakpoints from '../hooks/useBreakpoints';
import useNoScroll from '../hooks/useNoScroll';
import CartSummary from '../../profile/components/cart/CartSummary';
import { isImpersonating } from '../../../utils/impersonateClient';

const { Title } = Typography;

const { Sider, Content, Header } = Layout;

const Portal = ({
  title = "Let's Camp", Nav, children, userContext,
}) => {
  const { md } = useBreakpoints();

  // Variable to handle if menu is expanded or not
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  const mobileCollapse = () => {
    if (!md) {
      toggleCollapse();
    }
  };

  // Only block scroll on mobile, and if the menu is open
  useNoScroll(!md && !collapsed);

  return (
    <Layout className={style.portalLayout}>
      <Sider
        width={220}
        className={style.sidebar}
        breakpoint="lg"
        theme="light"
        collapsible
        trigger={
          (
            <>
              <span style={{ marginRight: '10px' }}>
                {collapsed ? <RightOutlined /> : <LeftOutlined />}
              </span>
              {
                !collapsed ? (
                  <FormattedMessage defaultMessage="Collapse" />
                ) : null
              }
            </>
          )
        }
        collapsed={collapsed}
        onCollapse={toggleCollapse}
        onBreakpoint={setCollapsed}
      >
        <Link to="/">
          <div className={style.logo}>
            <img src={logo} alt="" />
          </div>
        </Link>
        <Nav toggleCollapse={mobileCollapse} />
      </Sider>

      <Content>
        <Layout className={style.contentLayout}>
          <Header className={style.header}>
            <div className={style.mobileLogo}>
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>

            <Title level={3} className={style.portalTitle} style={{ lineHeight: 'inherit', marginBottom: 0 }}>
              {title}
            </Title>
            <div style={{ marginLeft: 'auto' }}>
              {
                userContext.user ? (
                  <div style={{ display: 'inline-block' }}>
                    <Divider type="vertical" />
                    {
                      userContext.user.type !== 'admin' || isImpersonating() ? (
                        <CartSummary style={{ color: 'black' }} admin />
                      ) : null
                    }
                    <div style={{ display: 'inline-block' }}>
                      <Menu
                        mode="horizontal"
                        className={style.userMenu}
                      >
                        <Menu.SubMenu
                          key="userMenu"
                          title={
                            (
                              <>
                                <Avatar icon={<UserOutlined />} style={{ marginRight: '0.5em' }} />
                                <span className={style.user}>
                                  {userContext.user.name}
                                </span>
                              </>
                            )
                          }
                        >
                          <Menu.Item key="logout">
                            <LogoutButton />
                          </Menu.Item>
                        </Menu.SubMenu>
                      </Menu>
                    </div>
                  </div>
                ) : null
              }
              <Button
                icon={<MenuOutlined />}
                onClick={toggleCollapse}
                className={style.hamburgerButton}
              />
            </div>
          </Header>
          <Content className={style.content}>
            { children }
          </Content>
          <Footer />
        </Layout>
      </Content>
    </Layout>
  );
};

export default withUser(Portal);
