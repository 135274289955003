import React from 'react';
import _ from 'lodash';
import { Alert, Form, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import style from './SubmissionError.module.less';

const { Text } = Typography;

const SubmissionError = (props) => {
  const { errors, fields } = props;

  // If no errors, render nothing
  if (!Object.keys(errors || {}).length) {
    return null;
  }

  const fieldErrors = _.compact(
    (fields ?? []).map((field) => {
      if (errors[field]) {
        return (
          <li key={field}>{errors[field].message}</li>
        );
      }
      return null;
    }),
  );

  return (
    <Form.Item>
      <Alert
        type="error"
        showIcon
        message={
          (
            <>
              <FormattedMessage defaultMessage="There were problems with your submission. Please review and try again." />
              {
                fieldErrors.length ? (
                  <Text>
                    <ul>
                      {
                        fieldErrors
                      }
                    </ul>
                  </Text>
                ) : null
              }
            </>
          )
        }
        className={fields ? style.alert : null}
      />
    </Form.Item>
  );
};

export default SubmissionError;
