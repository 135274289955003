import React from 'react';
import Number from './Number';
import { CURRENCIES } from '../../../../config/constants/constants';

function MoneyInput(props) {
  const { currency } = props;

  const symbol = CURRENCIES[currency] ? CURRENCIES[currency].symbol : CURRENCIES.default.symbol;
  const parserRegex = new RegExp(`${symbol === '$' ? '\\$' : symbol}\\s?|(,*)`, 'g');
  const decimals = CURRENCIES[currency] && CURRENCIES[currency].decimals
    ? CURRENCIES[currency].decimals : CURRENCIES.default.decimals;
  const step = 1 / (10 ** decimals);

  return (
    <Number
      step={step}
      formatter={(value) => `${symbol}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value.replace(parserRegex, '')}
      {...props}
    />
  );
}

export default MoneyInput;
