import React from 'react';
import { Router } from 'react-router-dom';
import history from './utils/history';
import AppProviders from './AppProviders';
import Scripts from './Scripts';
import ScrollToTop from './ScrollToTop';
import ErrorHandler from './ErrorHandler';
import HistoryRecorder from './HistoryRecorder';
import Routes from './bundles/common/components/Routes';
import './bundles/common/components/inputs'; // Import inputs so they are not code split (decreases build time a ton)
import SEO from './bundles/common/components/SEO';

function App() {
  return (
    <AppProviders>
      <SEO />
      <Scripts />
      <Router history={history}>
        <HistoryRecorder>
          <ErrorHandler>
            <ScrollToTop />
            <Routes />
          </ErrorHandler>
        </HistoryRecorder>
      </Router>
    </AppProviders>
  );
}

export default App;
