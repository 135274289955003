import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { LogoutOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { withUser } from '../contexts/UserContext';
import { LOGOUT_URL } from '../../../config/constants/constants';

class LogoutButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  // Clear authorization token and redirect to login page on click
  handleClick(event) {
    event.preventDefault();

    const { userContext, history } = this.props;

    // Remove user data stored locally
    userContext.logout();

    history.push(LOGOUT_URL);
  }

  render() {
    return (
      <>
        <div
          onClick={this.handleClick}
          role="button"
          onKeyDown={(event) => { if (event.keyCode === 32) { this.handleClick(event); } }}
          tabIndex="0"
        >
          <LogoutOutlined />
          <span><FormattedMessage defaultMessage="Logout" /></span>
        </div>
      </>
    );
  }
}

export default withUser(withRouter(LogoutButton));
