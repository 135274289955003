import React, { useCallback, useState } from 'react';
import { addMonths } from 'date-fns';
import getCookieConsent from '../../../utils/getCookieConsent';
import { COOKIE_CONSENT_EXPIRY_MONTHS } from '../../../config/constants/constants';

const CookieConsentContext = React.createContext();

/**
 * This class serves as a wrapper for the CookieConsentContext.Provider.
 * this will provide all consumers with the current cookie consent and a way to update it.
 */
export const CookieConsentProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(getCookieConsent());

  const setConsent = useCallback((analyticalConsent) => {
    const consent = {
      analyticalConsent,
      expiry: addMonths(new Date(), COOKIE_CONSENT_EXPIRY_MONTHS), // Expire after 6 months
    };
    localStorage.setItem('cookieConsent', JSON.stringify(consent));
    setCookieConsent(consent);
  }, [setCookieConsent]);

  return (
    <CookieConsentContext.Provider
      value={
        {
          cookieConsent,
          setConsent,
        }
      }
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

export const CookieConsentConsumer = CookieConsentContext.Consumer;

export default CookieConsentContext;
