import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, Form, Select } from 'antd';
import { errorProps } from '../../../../utils/errors';
import { DISTANCE_UNITS } from '../../../../config/constants/constants';
import Label from './Label';
import style from './MeasurementInput.module.less';

const { Option } = Select;

class MeasurementInput extends Component {
  constructor(props) {
    super(props);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
  }

  handleAmountChange(event) {
    const { name, value } = event.target;
    const { onChange } = this.props;

    // Name from event already has `amount` appended
    onChange(name, parseFloat(value));
  }

  handleUnitChange(value) {
    const { onChange, name, unitName = 'unit' } = this.props;

    onChange(`${name}.${unitName}`, value);
  }

  render() {
    const {
      name,
      unitName = 'unit',
      amountName = 'amount',
      textArea,
      value,
      label,
      placeholder,
      errors,
      onChange,
      unitProps,
      required,
      tooltip,
      ...otherProps
    } = this.props;

    return (
      <Form.Item
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        required={required}
        {...errorProps(errors, `${name}`)}
        {...errorProps(errors, `${name}.${unitName}`)}
        {...errorProps(errors, `${name}.${amountName}`)}
      >
        <Input
          className={style.input}
          name={`${name}.${amountName}`}
          placeholder={placeholder || label}
          value={value[amountName]}
          onChange={this.handleAmountChange}
          type="number"
          min={0}
          {...otherProps}
          addonAfter={
            (
              <Select
                name={`${name}.${unitName}`}
                value={value[unitName]}
                onChange={this.handleUnitChange}
                style={{ minWidth: '100px' }}
                {...unitProps}
              >
                {
                  DISTANCE_UNITS.map((unit) => (
                    <Option key={unit.value} value={unit.value}>
                      <FormattedMessage {...unit.translation} />
                    </Option>
                  ))
                }
              </Select>
            )
          }
        />
      </Form.Item>
    );
  }
}

export default MeasurementInput;
