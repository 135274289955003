import React, { Component } from 'react';
import { InputNumber, Form } from 'antd';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';

class Number extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    const { onChange, name } = this.props;
    let newValue = value;

    if (newValue === '') {
      newValue = null;
    }

    onChange(name, newValue);
  }

  render() {
    const {
      name,
      value,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      ...otherProps
    } = this.props;

    return (
      <Form.Item
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        required={required}
        {...errorProps(errors, name)}
      >
        <InputNumber
          style={{ width: '100%' }}
          name={name}
          // Note: Placeholder will only appear if value is undefined
          value={value == null ? undefined : value}
          placeholder={placeholder || label}
          onChange={this.handleChange}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

export default Number;
