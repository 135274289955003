import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Portal from '../../common/components/Portal';
import Nav from './Nav';
import { ClientProvider, ClientConsumer } from '../contexts/ClientContext';
import { getActiveCampId } from '../utils/activeCamp';

const DashboardContainer = lazy(() => import('./dashboard/DashboardContainer'));

const ContactEditContainer = lazy(() => import('./contact/ContactEditContainer'));

const RefundPolicyEditContainer = lazy(() => import('./refundPolicy/RefundPolicyEditContainer'));

const LongWeekendRulesEditContainer = lazy(() => import('./longWeekendRule/LongWeekendRulesEditContainer'));

const NotFoundError = lazy(() => import('../../common/components/NotFoundError'));

const CampInfoEditContainer = lazy(() => import('./camp/CampInfoEditContainer'));
const CampSettingsEditContainer = lazy(() => import('./camp/CampSettingsEditContainer'));
const SeasonalSettingsEditContainer = lazy(() => import('./camp/seasonalSettings/SeasonalSettingsEditContainer'));
const CampInfoViewContainer = lazy(() => import('./camp/CampInfoViewContainer'));
const CampSettingsViewContainer = lazy(() => import('./camp/CampSettingsViewContainer'));
const CampLiveStatusEditContainer = lazy(() => import('./camp/CampLiveStatusEditContainer'));
const CampMapViewContainer = lazy(() => import('./camp/CampMapViewContainer'));

const SeasonIndexContainer = lazy(() => import('./season/SeasonIndexContainer'));
const SeasonViewContainer = lazy(() => import('./season/SeasonViewContainer'));
const SeasonCreateContainer = lazy(() => import('./season/SeasonCreateContainer'));
const SeasonEditContainer = lazy(() => import('./season/SeasonEditContainer'));

const SiteBlockIndexContainer = lazy(() => import('./siteBlock/SiteBlockIndexContainer'));
const SiteBlockViewContainer = lazy(() => import('./siteBlock/SiteBlockViewContainer'));
const SiteBlockCreateContainer = lazy(() => import('./siteBlock/SiteBlockCreateContainer'));
const SiteBlockEditContainer = lazy(() => import('./siteBlock/SiteBlockEditContainer'));

const PriceModelIndexContainer = lazy(() => import('./priceModel/PriceModelIndexContainer'));
const PriceModelViewContainer = lazy(() => import('./priceModel/PriceModelViewContainer'));
const PriceModelCreateContainer = lazy(() => import('./priceModel/PriceModelCreateContainer'));
const PriceModelEditContainer = lazy(() => import('./priceModel/PriceModelEditContainer'));

const FeeIndexContainer = lazy(() => import('./fee/FeeIndexContainer'));
const FeeViewContainer = lazy(() => import('./fee/FeeViewContainer'));
const FeeCreateContainer = lazy(() => import('./fee/FeeCreateContainer'));
const FeeEditContainer = lazy(() => import('./fee/FeeEditContainer'));

const ProductIndexContainer = lazy(() => import('./product/ProductIndexContainer'));
const ProductViewContainer = lazy(() => import('./product/ProductViewContainer'));
const ProductCreateContainer = lazy(() => import('./product/ProductCreateContainer'));
const ProductEditContainer = lazy(() => import('./product/ProductEditContainer'));

const PriceThresholdIndexContainer = lazy(() => import('./priceThreshold/PriceThresholdIndexContainer'));
const PriceThresholdViewContainer = lazy(() => import('./priceThreshold/PriceThresholdViewContainer'));
const PriceThresholdCreateContainer = lazy(() => import('./priceThreshold/PriceThresholdCreateContainer'));
const PriceThresholdEditContainer = lazy(() => import('./priceThreshold/PriceThresholdEditContainer'));

const SiteTemplateIndexContainer = lazy(() => import('./siteTemplate/SiteTemplateIndexContainer'));
const SiteTemplateViewContainer = lazy(() => import('./siteTemplate/SiteTemplateViewContainer'));
const SiteTemplateCreateContainer = lazy(() => import('./siteTemplate/SiteTemplateCreateContainer'));
const SiteTemplateEditContainer = lazy(() => import('./siteTemplate/SiteTemplateEditContainer'));

const SiteTypeIndexContainer = lazy(() => import('./siteType/SiteTypeIndexContainer'));
const SiteTypeViewContainer = lazy(() => import('./siteType/SiteTypeViewContainer'));
const SiteTypeCreateContainer = lazy(() => import('./siteType/SiteTypeCreateContainer'));
const SiteTypeEditContainer = lazy(() => import('./siteType/SiteTypeEditContainer'));

const UserIndexContainer = lazy(() => import('./user/UserIndexContainer'));
const UserViewContainer = lazy(() => import('./user/UserViewContainer'));
const UserCreateContainer = lazy(() => import('./user/UserCreateContainer'));
const BookedUserIndexContainer = lazy(() => import('./user/BookedUserIndexContainer'));
const BookedUserViewContainer = lazy(() => import('./user/BookedUserViewContainer'));

const SiteIndexContainer = lazy(() => import('./site/SiteIndexContainer'));
const SiteViewContainer = lazy(() => import('./site/SiteViewContainer'));
const SiteCreateContainer = lazy(() => import('./site/SiteCreateContainer'));
const SiteEditContainer = lazy(() => import('./site/SiteEditContainer'));
const SiteUpload = lazy(() => import('./site/SiteUpload'));

const TaxIndexContainer = lazy(() => import('./tax/TaxIndexContainer'));
const TaxViewContainer = lazy(() => import('./tax/TaxViewContainer'));
const TaxCreate = lazy(() => import('./tax/TaxCreate'));
const TaxEditContainer = lazy(() => import('./tax/TaxEditContainer'));

const PromoCodeIndexContainer = lazy(() => import('./promoCode/PromoCodeIndexContainer'));
const PromoCodeCreateContainer = lazy(() => import('./promoCode/PromoCodeCreateContainer'));
const PromoCodeViewContainer = lazy(() => import('./promoCode/PromoCodeViewContainer'));

const SaleIndexContainer = lazy(() => import('./sale/SaleIndexContainer'));
const SaleCreateContainer = lazy(() => import('./sale/SaleCreateContainer'));
const SaleEditContainer = lazy(() => import('./sale/SaleEditContainer'));
const SalePaymentContainer = lazy(() => import('./sale/SalePaymentContainer'));
const SaleViewContainer = lazy(() => import('./sale/SaleViewContainer'));
const SaleRefundAddContainer = lazy(() => import('./sale/SaleRefundAddContainer'));

const ReportIndex = lazy(() => import('./report/ReportIndex'));
const TransactionReportContainer = lazy(() => import('./report/TransactionReportContainer'));
const BookingReportTypeContainer = lazy(() => import('./report/BookingReportTypeContainer'));
const ProductReportTypeContainer = lazy(() => import('./report/ProductReportTypeContainer'));
const DetailedBookingFeeReportContainer = lazy(() => import('./report/DetailedBookingFeeReportContainer'));
const PayoutReportContainer = lazy(() => import('./report/PayoutReportContainer'));
const PayoutTransactionReportContainer = lazy(() => import('./report/PayoutTransactionReportContainer'));

const BookingCreateContainer = lazy(() => import('./booking/BookingCreateContainer'));
const BookingPaymentAddContainer = lazy(() => import('./booking/BookingPaymentAddContainer'));
const BookingIndexContainer = lazy(() => import('./booking/BookingIndexContainer'));
const BookingViewContainer = lazy(() => import('./booking/BookingViewContainer'));
const BookingInfoEditContainer = lazy(() => import('./booking/BookingInfoEditContainer'));
const BookingRefundAddContainer = lazy(() => import('./booking/BookingRefundAddContainer'));
const BookingReservationEditContainer = lazy(() => import('./booking/BookingReservationEditContainer'));
const BookingAddOnEditContainer = lazy(() => import('./booking/BookingAddOnEditContainer'));
const BookingCancelConfirmContainer = lazy(() => import('./booking/BookingCancelConfirmContainer'));
const BookingAdminRefundContainer = lazy(() => import('./booking/BookingAdminRefundContainer'));

const BookingPriceAdjustmentsContainer = lazy(() => import('./booking/priceAdjustments/BookingPriceAdjustmentsContainer'));

const EventRegistrationCreateContainer = lazy(() => import('./eventRegistration/EventRegistrationCreateContainer'));
const EventRegistrationIndexContainer = lazy(() => import('./eventRegistration/EventRegistrationIndexContainer'));
const EventRegistrationViewContainer = lazy(() => import('./eventRegistration/EventRegistrationViewContainer'));
const EventRegistrationRefundAddContainer = lazy(() => import('./eventRegistration/EventRegistrationRefundAddContainer'));
const EventRegistrationPaymentAddContainer = lazy(() => import('./eventRegistration/EventRegistrationPaymentAddContainer'));
const EventRegistrationCancelConfirmContainer = lazy(() => import('./eventRegistration/EventRegistrationCancelConfirmContainer'));
const EventRegistrationInfoEditContainer = lazy(() => import('./eventRegistration/EventRegistrationInfoEditContainer'));
const EventRegistrationTicketEditContainer = lazy(() => import('./eventRegistration/EventRegistrationTicketEditContainer'));

const LogIndexContainer = lazy(() => import('./logs/LogIndexContainer'));
const LogViewContainer = lazy(() => import('./logs/LogViewContainer'));

const DenyListEntryIndexContainer = lazy(() => import('./denyListEntry/DenyListEntryIndexContainer'));
const DenyListEntryCreate = lazy(() => import('./denyListEntry/DenyListEntryCreate'));

const WaitlistIndexContainer = lazy(() => import('./waitlist/WaitlistIndexContainer'));
const WaitlistViewContainer = lazy(() => import('./waitlist/WaitlistViewContainer'));
const WaitlistCreateContainer = lazy(() => import('./waitlist/WaitlistCreateContainer'));
const WaitlistEditContainer = lazy(() => import('./waitlist/WaitlistEditContainer'));

const Settings = lazy(() => import('./settings/ClientSettings'));

const EventIndexContainer = lazy(() => import('./event/EventIndexContainer'));
const EventViewContainer = lazy(() => import('./event/EventViewContainer'));
const EventCreateContainer = lazy(() => import('./event/EventCreateContainer'));
const EventEditContainer = lazy(() => import('./event/EventEditContainer'));

const TransactionAllocationLogIndexContainer = lazy(() => import('./transactionAllocationLog/TransactionAllocationLogIndexContainer'));

const AttractionIndexContainer = lazy(() => import('./attraction/AttractionIndexContainer'));
const AttractionViewContainer = lazy(() => import('./attraction/AttractionViewContainer'));
const AttractionCreateContainer = lazy(() => import('./attraction/AttractionCreateContainer'));
const AttractionEditContainer = lazy(() => import('./attraction/AttractionEditContainer'));

const CartInitialPaymentContainer = lazy(() => import('./cart/CartInitialPaymentContainer'));
const CartCheckoutContainer = lazy(() => import('../../profile/components/cart/checkout/CartCheckoutContainer'));
const CartViewContainer = lazy(() => import('../../profile/components/cart/CartViewContainer'));

/**
 * Serves all the routes for the client portal. Wrapped in ClientProvider
 * to make client and camp information available in entire client portal.
 */
function Routes() {
  return (
    <ClientProvider>
      <ClientConsumer>
        {
          ({ camp }) => (
            <Portal Nav={Nav} title={camp ? camp.name : undefined}>
              {/*
                Only render internal portal routes once a camp has been loaded,
                because if the user goes directly to the route, it might make a bad
                API request because the camp hasn't been verified and loaded into the context yet
              */}

              {
                camp ? (
                  /*
                    Wrap /client/:campId to make it easier to prefix all routes
                    with /client/:campId
                  */
                  <Route
                    path="/client/:campId"
                    component={
                      ({ match }) => (
                        <Switch>
                          <Route exact path={match.path} component={DashboardContainer} />
                          <Route exact path={`${match.path}/contacts`} component={ContactEditContainer} />
                          <Route exact path={`${match.path}/refund-policies`} component={RefundPolicyEditContainer} />
                          <Route exact path={`${match.path}/long-weekend-rules`} component={LongWeekendRulesEditContainer} />
                          <Route exact path={`${match.path}/info`} component={CampInfoViewContainer} />
                          <Route exact path={`${match.path}/settings`} component={CampSettingsViewContainer} />
                          <Route exact path={`${match.path}/info/edit`} component={CampInfoEditContainer} />
                          <Route exact path={`${match.path}/settings/edit`} component={CampSettingsEditContainer} />
                          <Route exact path={`${match.path}/seasonal-settings/edit`} component={SeasonalSettingsEditContainer} />
                          <Route exact path={`${match.path}/live-status`} component={CampLiveStatusEditContainer} />
                          <Route exact path={`${match.path}/map`} component={CampMapViewContainer} />

                          <Route exact path={`${match.path}/seasons`} component={SeasonIndexContainer} />
                          <Route exact path={`${match.path}/seasons/new`} component={SeasonCreateContainer} />
                          <Route exact path={`${match.path}/seasons/:id`} component={SeasonViewContainer} />
                          <Route exact path={`${match.path}/seasons/:id/edit`} component={SeasonEditContainer} />

                          <Route exact path={`${match.path}/site-blocks`} component={SiteBlockIndexContainer} />
                          <Route exact path={`${match.path}/site-blocks/new`} component={SiteBlockCreateContainer} />
                          <Route exact path={`${match.path}/site-blocks/:id`} component={SiteBlockViewContainer} />
                          <Route exact path={`${match.path}/site-blocks/:id/edit`} component={SiteBlockEditContainer} />

                          <Route exact path={`${match.path}/price-models`} component={PriceModelIndexContainer} />
                          <Route exact path={`${match.path}/price-models/new`} component={PriceModelCreateContainer} />
                          <Route exact path={`${match.path}/price-models/:id`} component={PriceModelViewContainer} />
                          <Route exact path={`${match.path}/price-models/:id/edit`} component={PriceModelEditContainer} />

                          <Route exact path={`${match.path}/fees`} component={FeeIndexContainer} />
                          <Route exact path={`${match.path}/fees/new`} component={FeeCreateContainer} />
                          <Route exact path={`${match.path}/fees/:id`} component={FeeViewContainer} />
                          <Route exact path={`${match.path}/fees/:id/edit`} component={FeeEditContainer} />

                          <Route exact path={`${match.path}/products`} component={ProductIndexContainer} />
                          <Route exact path={`${match.path}/products/new`} component={ProductCreateContainer} />
                          <Route exact path={`${match.path}/products/:id`} component={ProductViewContainer} />
                          <Route exact path={`${match.path}/products/:id/edit`} component={ProductEditContainer} />

                          <Route exact path={`${match.path}/price-thresholds`} component={PriceThresholdIndexContainer} />
                          <Route exact path={`${match.path}/price-thresholds/new`} component={PriceThresholdCreateContainer} />
                          <Route exact path={`${match.path}/price-thresholds/:id`} component={PriceThresholdViewContainer} />
                          <Route exact path={`${match.path}/price-thresholds/:id/edit`} component={PriceThresholdEditContainer} />

                          <Route exact path={`${match.path}/site-templates`} component={SiteTemplateIndexContainer} />
                          <Route exact path={`${match.path}/site-templates/new`} component={SiteTemplateCreateContainer} />
                          <Route exact path={`${match.path}/site-templates/:id`} component={SiteTemplateViewContainer} />
                          <Route exact path={`${match.path}/site-templates/:id/edit`} component={SiteTemplateEditContainer} />

                          <Route exact path={`${match.path}/site-types`} component={SiteTypeIndexContainer} />
                          <Route exact path={`${match.path}/site-types/new`} component={SiteTypeCreateContainer} />
                          <Route exact path={`${match.path}/site-types/:id`} component={SiteTypeViewContainer} />
                          <Route exact path={`${match.path}/site-types/:id/edit`} component={SiteTypeEditContainer} />

                          <Route exact path={`${match.path}/users`} component={UserIndexContainer} />
                          <Route exact path={`${match.path}/guest-users`} component={BookedUserIndexContainer} />
                          <Route exact path={`${match.path}/guest-users/:id`} component={BookedUserViewContainer} />
                          <Route exact path={`${match.path}/users/new`} component={UserCreateContainer} />
                          <Route exact path={`${match.path}/users/:id`} component={UserViewContainer} />

                          <Route exact path={`${match.path}/sites`} component={SiteIndexContainer} />
                          <Route exact path={`${match.path}/sites/new`} component={SiteCreateContainer} />
                          <Route exact path={`${match.path}/site-upload`} component={SiteUpload} />
                          <Route exact path={`${match.path}/sites/:id`} component={SiteViewContainer} />
                          <Route exact path={`${match.path}/sites/:id/edit`} component={SiteEditContainer} />

                          <Route exact path={`${match.path}/taxes`} component={TaxIndexContainer} />
                          <Route exact path={`${match.path}/taxes/new`} component={TaxCreate} />
                          <Route exact path={`${match.path}/taxes/:id`} component={TaxViewContainer} />
                          <Route exact path={`${match.path}/taxes/:id/edit`} component={TaxEditContainer} />

                          <Route exact path={`${match.path}/promo-codes`} component={PromoCodeIndexContainer} />
                          <Route exact path={`${match.path}/promo-codes/new`} component={PromoCodeCreateContainer} />
                          <Route exact path={`${match.path}/promo-codes/:id`} component={PromoCodeViewContainer} />

                          <Route exact path={`${match.path}/sales`} component={SaleIndexContainer} />
                          <Route exact path={`${match.path}/sales/new`} component={SaleCreateContainer} />
                          <Route exact path={`${match.path}/sales/:id`} component={SaleViewContainer} />
                          <Route exact path={`${match.path}/sales/:id/payments/new`} component={SalePaymentContainer} />
                          <Route exact path={`${match.path}/sales/:id/refunds/new`} component={SaleRefundAddContainer} />
                          <Route exact path={`${match.path}/sales/:id/edit`} component={SaleEditContainer} />

                          <Route exact path={`${match.path}/reports`} component={ReportIndex} />
                          <Route exact path={`${match.path}/reports/transaction-report`} component={TransactionReportContainer} />
                          <Route exact path={`${match.path}/reports/booking-report/booking-date`} component={BookingReportTypeContainer} />
                          <Route exact path={`${match.path}/reports/booking-report/reserved-date`} render={(routeProps) => <BookingReportTypeContainer byCreationDate {...routeProps} />} />
                          <Route exact path={`${match.path}/reports/product-sales-report`} component={ProductReportTypeContainer} />
                          <Route exact path={`${match.path}/reports/booking-fee-report`} component={DetailedBookingFeeReportContainer} />
                          <Route exact path={`${match.path}/reports/payout-report`} component={PayoutReportContainer} />
                          <Route exact path={`${match.path}/reports/payout-transaction-report`} component={PayoutTransactionReportContainer} />

                          <Route exact path={`${match.path}/bookings`} component={BookingIndexContainer} />
                          <Route
                            exact
                            path={`${match.path}/seasonal-bookings`}
                            render={
                              () => (
                                <BookingIndexContainer seasonal />
                              )
                            }
                          />
                          <Route exact path={[`${match.path}/bookings/new`, `${match.path}/seasonal-bookings/new`]} component={BookingCreateContainer} />
                          <Route exact path={[`${match.path}/bookings/:id`, `${match.path}/seasonal-bookings/:id`]} component={BookingViewContainer} />
                          <Route exact path={`${match.path}/bookings/:id/payments/new`} component={BookingPaymentAddContainer} />
                          <Route exact path={`${match.path}/bookings/:id/edit`} component={BookingInfoEditContainer} />
                          <Route exact path={`${match.path}/bookings/:id/refunds/new`} component={BookingRefundAddContainer} />
                          <Route exact path={`${match.path}/bookings/:id/reservation/edit`} component={BookingReservationEditContainer} />
                          <Route exact path={`${match.path}/bookings/:id/add-ons/edit`} component={BookingAddOnEditContainer} />
                          <Route exact path={`${match.path}/bookings/:id/cancel`} component={BookingCancelConfirmContainer} />
                          <Route exact path={`${match.path}/bookings/:id/adjust-prices`} component={BookingPriceAdjustmentsContainer} />
                          <Route exact path={`${match.path}/bookings/:id/refund-card`} component={BookingAdminRefundContainer} />

                          <Route exact path={`${match.path}/event-registrations`} component={EventRegistrationIndexContainer} />
                          <Route exact path={`${match.path}/event-registrations/:id`} component={EventRegistrationViewContainer} />
                          <Route exact path={`${match.path}/event-registrations/:id/refunds/new`} component={EventRegistrationRefundAddContainer} />
                          <Route exact path={`${match.path}/event-registrations/:id/payments/new`} component={EventRegistrationPaymentAddContainer} />
                          <Route exact path={`${match.path}/event-registrations/:id/cancel`} component={EventRegistrationCancelConfirmContainer} />
                          <Route exact path={`${match.path}/event-registrations/:id/guest-info/edit`} component={EventRegistrationInfoEditContainer} />
                          <Route exact path={`${match.path}/event-registrations/:id/tickets/edit`} component={EventRegistrationTicketEditContainer} />

                          <Route exact path={`${match.path}/cart/payments/initial`} component={CartInitialPaymentContainer} />
                          <Route exact path={`${match.path}/cart`}>
                            {
                              (routeProps) => (
                                <CartViewContainer
                                  admin
                                  {...routeProps}
                                />
                              )
                            }
                          </Route>
                          <Route exact path={`${match.path}/cart/checkout`}>
                            {
                              (routeProps) => (
                                <CartCheckoutContainer
                                  admin
                                  campId={getActiveCampId()}
                                  {...routeProps}
                                />
                              )
                            }
                          </Route>

                          <Route exact path={`${match.path}/bookings/:id/logs`}>
                            {
                              (routeProps) => (
                                <LogIndexContainer
                                  endpointUrl={`/bookings/${routeProps.match.params.id}/logs`}
                                  backUrl={`/bookings/${routeProps.match.params.id}`}
                                />
                              )
                            }
                          </Route>
                          <Route exact path={`${match.path}/bookings/:id/logs/:logId`}>
                            {
                              (routeProps) => (
                                <LogViewContainer
                                  endpointUrl={`/bookings/${routeProps.match.params.id}/logs/${routeProps.match.params.logId}`}
                                  documentField="booking"
                                />
                              )
                            }
                          </Route>

                          <Route exact path={`${match.path}/events`} component={EventIndexContainer} />
                          <Route exact path={`${match.path}/events/new`} component={EventCreateContainer} />
                          <Route exact path={`${match.path}/events/:id`} component={EventViewContainer} />
                          <Route exact path={`${match.path}/events/:id/edit`} component={EventEditContainer} />
                          <Route exact path={`${match.path}/events/:id/event-registrations/new`} component={EventRegistrationCreateContainer} />

                          <Route exact path={`${match.path}/waitlist`} component={WaitlistIndexContainer} />
                          <Route exact path={`${match.path}/waitlist/new`} component={WaitlistCreateContainer} />
                          <Route exact path={`${match.path}/waitlist/:id`} component={WaitlistViewContainer} />
                          <Route exact path={`${match.path}/waitlist/:id/edit`} component={WaitlistEditContainer} />

                          <Route exact path={`${match.path}/allocation-log/:id`} component={TransactionAllocationLogIndexContainer} />

                          <Route exact path={`${match.path}/attractions`} component={AttractionIndexContainer} />
                          <Route exact path={`${match.path}/attractions/new`} component={AttractionCreateContainer} />
                          <Route exact path={`${match.path}/attractions/:id`} component={AttractionViewContainer} />
                          <Route exact path={`${match.path}/attractions/:id/edit`} component={AttractionEditContainer} />

                          <Route exact path={`${match.path}/deny-list`} component={DenyListEntryIndexContainer} />
                          <Route exact path={`${match.path}/deny-list/new`} component={DenyListEntryCreate} />

                          <Route exact path={`${match.path}/camp-settings`} component={Settings} />

                          <Route component={NotFoundError} />
                        </Switch>
                      )
                    }
                  />
                ) : null
              }
            </Portal>
          )
        }
      </ClientConsumer>
    </ClientProvider>
  );
}

export default Routes;
