import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { IconContext } from 'react-icons';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Elements } from '@stripe/react-stripe-js';
import { AbilityContext } from './bundles/common/contexts/AbilityContext';
import ability from './utils/ability';
import { UserProvider } from './bundles/common/contexts/UserContext';
import { AlertProvider } from './bundles/common/contexts/AlertContext';
import { HistoryStackProvider } from './bundles/common/contexts/HistoryStackContext';
import { CookieConsentProvider } from './bundles/common/contexts/CookieConsentContext';
import { CartProvider } from './bundles/common/contexts/CartContext';
import stripe from './utils/stripe';

import { appLocale } from './config/setupConfig';

const CreditCardFonts = [
  // sofia-pro
  { cssSrc: 'https://use.typekit.net/gme8pet.css' },
];

const AppProviders = ({ children }) => (
  <ConfigProvider locale={appLocale.antd}>
    <IntlProvider
      locale={appLocale.locale}
      defaultLocale={appLocale.locale}
      messages={appLocale.messages}
      textComponent={React.Fragment}
    >
      {/* Give the antd icon class name to react icons to allow compatibility */}
      <IconContext.Provider value={{ className: 'anticon' }}>
        <GoogleReCaptchaProvider
          language={appLocale.code}
          useRecaptchaNet
          reCaptchaKey={window.ENV.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY}
        >
          <AbilityContext.Provider value={ability}>
            <UserProvider>
              <AlertProvider>
                <HistoryStackProvider>
                  <CookieConsentProvider>
                    <CartProvider>
                      <Elements
                        stripe={stripe}
                        options={{ locale: appLocale.locale, fonts: CreditCardFonts }}
                      >
                        {children}
                      </Elements>
                    </CartProvider>
                  </CookieConsentProvider>
                </HistoryStackProvider>
              </AlertProvider>
            </UserProvider>
          </AbilityContext.Provider>
        </GoogleReCaptchaProvider>
      </IconContext.Provider>
    </IntlProvider>
  </ConfigProvider>
);

export default AppProviders;
