import React, { Component } from 'react';
import { Form } from 'antd';
import {
  parseISO,
  isSameDay, isBefore,
  isAfter, formatISO,
} from 'date-fns';
import DatePicker from './util/OverriddenDatePicker';
import { errorProps } from '../../../../utils/errors';
import isBetween from '../../../../utils/isBetween';
import Label from './Label';
import style from './RangePicker.module.less';

/**
 * Converts a date only string into an ISO date string with a timezone. The time will be midnight in
 * the user's timezone so that the API can correctly respond to the user's local time instead of
 * relying on UTC.
 *
 * @param {*} dateString ISO date string without time
 */
const getDateWithTimeZone = (dateString) => (
  /**
   * When parsing a date (no time) string, date-fns will make it midnight in the current timezone,
   * whereas Date will make it midnight in UTC, which would be the wrong date.
   */
  parseISO(dateString)
);

class RangePicker extends Component {
  constructor(props) {
    super(props);
    const {
      startName,
      endName,
    } = props;
    this.startName = startName;
    this.endName = endName;
    this.handleChange = this.handleChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { withTimeZone, startValue, endValue } = this.props;
    if (prevProps.withTimeZone !== withTimeZone) {
      let displayStartDate = startValue || undefined;
      let displayEndDate = endValue || undefined;
      if (typeof displayStartDate === 'string') { displayStartDate = parseISO(displayStartDate); }
      if (typeof displayEndDate === 'string') { displayEndDate = parseISO(displayEndDate); }
      if (displayStartDate) { this.handleStartDateChange(startValue, formatISO(displayStartDate, { representation: 'date' })); }
      if (displayEndDate) { this.handleEndDateChange(endValue, formatISO(displayEndDate, { representation: 'date' })); }
    }
  }

  handleChange(name, dateString) {
    const {
      onChange, withTimeZone,
    } = this.props;
    let value = dateString;

    if (value && withTimeZone) {
      value = getDateWithTimeZone(dateString).toISOString();
    }
    onChange(name, value);
  }

  handleStartDateChange(startDate, dateString) {
    const {
      allowSameDate, endValue,
    } = this.props;

    this.handleChange(this.startName, dateString);

    if (endValue) {
      const currentEndValue = parseISO(endValue);

      // If the new start value is after the current end value
      // or is the same value and same dates aren't allowed
      if (
        isAfter(startDate, currentEndValue)
        || (isSameDay(startDate, currentEndValue) && !allowSameDate)
      ) {
        // unset the end date
        this.handleChange(this.endName, undefined);
      }
    }
  }

  handleEndDateChange(endDate, dateString) {
    const {
      allowSameDate, startValue,
    } = this.props;
    this.handleChange(this.endName, dateString);

    if (startValue) {
      const currentStartValue = parseISO(startValue);

      // If the new end value is before the current start value
      // or is the same value and same dates aren't allowed
      if (
        isBefore(endDate, currentStartValue)
        || (isSameDay(endDate, currentStartValue) && !allowSameDate)
      ) {
        // unset the start date
        this.handleChange(this.startName, undefined);
      }
    }
  }

  render() {
    const {
      startName,
      endName,
      startValue,
      endValue,
      label,
      startPlaceholder,
      endPlaceholder,
      errors,
      // Required here so it doesn't override the onChange function on the individual date pickers
      onChange,
      required,
      tooltip,
      disabledDate,
      disabled,
      allowSameDate,
      earliestMonth,
      ...otherProps
    } = this.props;

    let displayStartDate = startValue || undefined;
    let displayEndDate = endValue || undefined;
    if (typeof displayStartDate === 'string') { displayStartDate = parseISO(displayStartDate); }
    if (typeof displayEndDate === 'string') { displayEndDate = parseISO(displayEndDate); }

    const dateRender = (currentDate) => (
      <div
        className={
            `ant-picker-cell-inner ${
              displayStartDate && displayEndDate && isBetween(currentDate, displayStartDate, displayEndDate) ? style.inRange : ''
            } ${
              isSameDay(currentDate, displayStartDate) || isSameDay(currentDate, displayEndDate) ? style.rangeEdge : ''
            }`
          }
      >
        {currentDate.getDate()}
      </div>
    );

    return (
      <Form.Item
        required={required}
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        className={style.rangePicker}
        {...errorProps(errors, endName)}
        {...errorProps(errors, startName)}
      >
        <DatePicker
          name={startName}
          placeholder={startPlaceholder || undefined}
          value={displayStartDate}
          onChange={this.handleStartDateChange}
          disabled={
            disabled && Array.isArray(disabled) && disabled.length
              ? disabled[0] : Boolean(disabled)
          }
          disabledDate={
            (current) => (disabledDate ? disabledDate(current) : false)
          }
          defaultPickerValue={displayStartDate || earliestMonth}
          dateRender={dateRender}
          {...otherProps}
        />
        <DatePicker
          name={endName}
          placeholder={endPlaceholder || undefined}
          value={displayEndDate}
          onChange={this.handleEndDateChange}
          disabled={
            disabled && Array.isArray(disabled) && disabled.length
              ? disabled[1] : Boolean(disabled)
          }
          disabledDate={(current) => (disabledDate ? disabledDate(current, true) : false)}
          defaultPickerValue={displayEndDate || displayStartDate || earliestMonth}
          dateRender={dateRender}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

export default RangePicker;
