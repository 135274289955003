import React from 'react';
import {
  Layout, Row, Col,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF, faInstagram, faTwitter, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import style from './Footer.module.less';
// Images
import footerLogo from '../../../images/lets-camp-long-black-logo.png';
import { SOCIAL_LINKS, FOOTER_LINKS } from '../../../config/constants/constants';
import SubFooter from './SubFooter';

const Footer = () => {
  return (
    <Layout.Footer className={style.footer}>
      <Row>
        <Col lg={9} sm={12} xs={24} className={style.footerLogo}>
          <Link to="/">
            <img className={style.homeLogo} src={footerLogo} alt="Let's Camp Reservation System" />
          </Link>
          <div className={style.about}>
            <p><FormattedMessage defaultMessage="Let's Camp connects campers with campgrounds. Find and book your new favorite spot with our intuitive search and booking tools. Campgrounds can take advantage of our user-friendly tools to better manage their bookings and generate more revenue for their business." /></p>
          </div>
        </Col>
        <Col lg={5} sm={12} xs={24} className={style.footerSection}>
          <p className={style.title}><FormattedMessage defaultMessage="For Campers" /></p>
          <Link to="/camps">
            <FormattedMessage defaultMessage="Find a Campground" />
          </Link>
          <a href={FOOTER_LINKS.faq}>
            <FormattedMessage defaultMessage="Camper FAQ" />
          </a>
          <a href={FOOTER_LINKS.blog}>
            <FormattedMessage defaultMessage="Camper Blog" />
          </a>
          <Link to="/contact">
            <FormattedMessage defaultMessage="Contact Us" />
          </Link>
        </Col>
        <Col lg={5} sm={12} xs={24} className={style.footerSection}>
          <p className={style.title}><FormattedMessage defaultMessage="For Campgrounds" /></p>
          <Link to="/campground-sign-up">
            <FormattedMessage defaultMessage="Register My Campground" />
          </Link>
          <a href={FOOTER_LINKS.about}>
            <FormattedMessage defaultMessage="About Let's Camp" />
          </a>
          <a href={FOOTER_LINKS.campblog}>
            <FormattedMessage defaultMessage=" Campground Blog" />
          </a>
          <a href={FOOTER_LINKS.campsupport}>
            <FormattedMessage defaultMessage="Support" />
          </a>
        </Col>
        <Col lg={5} sm={12} xs={24} className={style.footerSection}>
          <p className={style.title}><FormattedMessage defaultMessage="Get Social" /></p>
          <div className={style.socialIcons}>
            <a href={SOCIAL_LINKS.facebook} target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href={SOCIAL_LINKS.instagram} target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href={SOCIAL_LINKS.twitter} target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href={SOCIAL_LINKS.youtube} target="_blank" rel="noopener noreferrer" className={style.socialIcon}>
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <p>#letscamp</p>
          </div>
        </Col>
      </Row>

      <hr />

      <SubFooter />
    </Layout.Footer>
  );
};

export default Footer;
