import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Result, Button } from 'antd';
import Icon from '@ant-design/icons';
import history from '../../../utils/history';
import trailer from '../../../icons/errors/trailer.svg';

const NotFoundError = () => (
  <Result
    icon={<Icon component={() => (<img src={trailer} alt="404" />)} />}
    title="404"
    subTitle={<FormattedMessage defaultMessage="This page cannot be found. Please try another page." />}
    extra={<Button type="primary" onClick={() => history.push('/')}><FormattedMessage defaultMessage="Go Home" /></Button>}
  />
);

export default NotFoundError;
