import React, { Component } from 'react';
import { Select as AntdSelect, Form } from 'antd';
import Label from './Label';
import HiddenInput from './HiddenInput';
import { errorProps } from '../../../../utils/errors';
import { COUNTRIES, GETALPHA2CODE } from '../../../../config/constants/constants';

const { Option } = AntdSelect;

class CountrySelect extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.state = {
      country: undefined,
    };
  }

  // Handle change for select input
  handleChange(value) {
    const { onChange, name } = this.props;
    this.setState({ country: undefined });

    let newValue = value;
    if (newValue === undefined) {
      newValue = null;
    }

    onChange(name, newValue);
  }

  // Handle autocomplete with hidden input field and update the select input
  handleCountryChange(fieldName, value) {
    const { onChange, name } = this.props;
    this.setState({ country: value });

    let newValue = GETALPHA2CODE(value);
    if (newValue === undefined) {
      newValue = null;
    }

    onChange(name, newValue);
  }

  render() {
    const {
      children,
      name,
      value,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      ...otherProps
    } = this.props;

    const { country } = this.state;

    return (
      <>
        <HiddenInput
          name={name}
          value={country}
          onChange={this.handleCountryChange}
        />
        <Form.Item
          label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
          required={required}
          {...errorProps(errors, name)}
        >
          <AntdSelect
            name={name}
                  // Note: Placeholder will only appear if value is undefined
            value={value === null ? undefined : value}
            placeholder={
              placeholder || label
            }
            onChange={this.handleChange}
            {...otherProps}
          >
            {
              Object.entries(COUNTRIES).map(([key, val]) => (
                <Option key={key} value={key}>{val}</Option>
              ))
            }
          </AntdSelect>
        </Form.Item>
      </>
    );
  }
}

export default CountrySelect;
