import React, { useState, useEffect } from 'react';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { differenceInSeconds } from 'date-fns';

const CartTimer = (props) => {
  const { lockedUntil, timeOnly = false } = props;
  const [color, setColor] = useState(parseInt(differenceInSeconds(lockedUntil, new Date()) / 60, 10) < 2 ? 'error' : 'warning');
  const [time, setTime] = useState({
    minutes: parseInt(differenceInSeconds(lockedUntil, new Date()) / 60, 10),
    seconds: parseInt(differenceInSeconds(lockedUntil, new Date()) % 60, 10),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setTime({
        minutes: parseInt(differenceInSeconds(lockedUntil, new Date()) / 60, 10),
        seconds: parseInt(differenceInSeconds(lockedUntil, new Date()) % 60, 10),
      });
      if (time.minutes > 2 && color !== 'warning') {
        setColor('warning');
      }
      if (time.minutes < 2 && color !== 'error') {
        setColor('error');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lockedUntil, time, color]);

  if (time.minutes >= 0 && time.seconds >= 0) {
    if (timeOnly) {
      return `${time.minutes}:${time.seconds < 10 ? `0${time.seconds}` : time.seconds}`;
    }
    return <Alert message={<FormattedMessage defaultMessage="{timeLeft} remaining to finish your reservation" values={{ timeLeft: `${time.minutes}:${time.seconds < 10 ? `0${time.seconds}` : time.seconds}` }} />} type={color} showIcon />;
  }
  return null;
};

export default CartTimer;
