import React, { useContext } from 'react';
import {
  Row, Col, Typography, Button,
} from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import style from './CookieConsent.module.less';
import CookieConsentContext from '../contexts/CookieConsentContext';

const { Title } = Typography;

const CookieConsent = (props) => {
  const { setShowModal } = props;
  const { cookieConsent, setConsent } = useContext(CookieConsentContext);
  if (!cookieConsent) {
    return (
      <div className={style.cookieConsent}>
        <Row>
          <Col xs={24} xl={12}>
            <Title level={5}><FormattedMessage defaultMessage="Your privacy is important to us!" /></Title>
            <p>
              <FormattedMessage
                defaultMessage="Let's Camp uses cookies for basic website functionality and analytics tracking, which helps us make informed upgrades and enhancements. To find out more, please read our <a>Privacy Policy</a>."
                values={
                  {
                    a: (text) => <Link to="/terms-conditions">{text}</Link>,
                  }
                }
              />
            </p>
          </Col>
          <Col xs={24} xl={12}>
            <div className={style.buttonRow}>
              <Button onClick={() => setConsent(true)} className={style.acceptButton}>
                <FormattedMessage defaultMessage="Accept All Cookies" />
              </Button>
              <Button onClick={() => setShowModal(true)} className={style.customizeButton}>
                <FormattedMessage defaultMessage="Customize" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return null;
};

export default CookieConsent;
