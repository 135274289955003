import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// Uncomment to re-enable French
// import fr from './locales/fr-FR';
import en from './locales/en-US';
import { handleServerError } from '../utils/errors';
import { stringifyQuery } from '../utils/queryString';
import history from '../utils/history';
import { getImpersonatedId } from '../utils/impersonateClient';

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  window.ENV = process.env;
}

// eslint-disable-next-line import/no-mutable-exports
let appLocale;

// Data needed to configure locales
const language = (navigator.languages && navigator.languages[0])
|| navigator.language
|| navigator.userLanguage;

const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

if (languageWithoutRegionCode === 'fr') {
  // Swap these lines to re-enable French
  // appLocale = fr;
  appLocale = en;
} else {
  appLocale = en;
}

export default () => {
  if (window.ENV.NODE_ENV !== 'development' && window.ENV.NODE_ENV !== 'test' && window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
  }

  // Variable used by date-fns for the locale
  // eslint-disable-next-line no-underscore-dangle
  window.__localeId__ = appLocale.code;

  axios.defaults.params = {
    locale: appLocale.code,
  };

  axios.defaults.baseURL = `${window.location.protocol}//${window.ENV.REACT_APP_BACKEND_HOST}`;

  // Set default Accept header for all requests
  axios.defaults.headers.common.Accept = 'application/json';

  // Request interceptor that will automatically add token as Authorization header
  axios.interceptors.request.use((config) => {
    const newConfig = config;

    // If there is a token, send it as an authorization header
    const token = localStorage.getItem('jwtToken');
    if (token) {
      newConfig.headers.Authorization = token;
    }

    // If we are impersonating, send it as a query parameter
    const impersonating = getImpersonatedId();
    if (impersonating) {
      newConfig.params = { ...newConfig.params, impersonating };
    }

    // Use qs to serialize get parameters
    newConfig.paramsSerializer = (params) => (
      stringifyQuery(params)
    );

    return newConfig;
  }, (error) => Promise.reject(error));

  // Response interceptor that will automatically call `handleServerError`
  axios.interceptors.response.use((response) => response, handleServerError);

  // Set up error logging to sentry, but only in production and staging environments
  if (window.ENV.NODE_ENV !== 'development' && window.ENV.NODE_ENV !== 'test') {
    /**
     * A list of common errors to ignore. Found at the following URLS:
     * https://docs.sentry.io/platforms/javascript/configuration/filtering/?original_referrer=https%3A%2F%2Fwww.google.com%2F#decluttering-sentry
     * https://gist.github.com/impressiver/5092952
     */
    const predefinedSentryOpts = {
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
      ],
      ignoreUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    };

    Sentry.init({
      dsn: 'https://72408fb639ee4ce799df79e6098e79a2@o924624.ingest.sentry.io/5872872',
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
      ],
      tracesSampleRate: 0.05,
      ignoreErrors: [
        ...predefinedSentryOpts.ignoreErrors,
        /Request failed with status code 40[0,3,4]/,
      ],
      ignoreUrls: [
        ...predefinedSentryOpts.ignoreUrls,
      ],
    });
  }
};

export { appLocale };
