import React from 'react';
import {
  Button, Form, Tooltip,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Label from './Label';
import { errorProps } from '../../../../utils/errors';
import classes from './NumberPicker.module.less';

const NumberPicker = (props) => {
  const {
    name,
    onChange,
    value,
    label,
    topLabel = false,
    errors,
    disableMinus = false,
    disablePlus = false,
    disabled = false,
    minusMessage,
    plusMessage,
    style = {},
  } = props;

  return (
    <Form.Item
      label={label && topLabel ? <Label text={label} /> : undefined}
      {...errorProps(errors, name)}
      style={{ ...style }}
    >
      {
        (() => {
          const minusButton = (
            <Button
              shape="circle"
              icon={<MinusOutlined />}
              style={{ color: '#e1e7e6', marginLeft: 'auto', marginRight: 8 }}
              disabled={disabled || disableMinus}
              onClick={() => onChange(name, value - 1)}
              className={classes.button}
            />
          );

          if (minusMessage) {
            return (
              <Tooltip title={minusMessage}>
                {minusButton}
              </Tooltip>
            );
          }

          return minusButton;
        })()
      }
      <span style={{ fontWeight: 600, fontSize: 16 }}>
        <Label text={value} />
      </span>
      {
        (() => {
          const plusButton = (
            <Button
              shape="circle"
              icon={<PlusOutlined />}
              style={{ color: '#e1e7e6', marginLeft: 8 }}
              disabled={disabled || disablePlus}
              onClick={() => onChange(name, value + 1)}
              className={classes.button}
            />
          );

          if (plusMessage) {
            return (
              <Tooltip title={plusMessage}>
                {plusButton}
              </Tooltip>
            );
          }

          return plusButton;
        })()
      }
      {
        label && !topLabel ? (
          <span style={{ marginLeft: 10 }}>
            {label}
          </span>
        ) : null
      }
    </Form.Item>
  );
};


export default NumberPicker;
