import React, { Component } from 'react';
import { Input, Form } from 'antd';
import { AsYouType } from 'libphonenumber-js';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';

class PhoneInput extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { onChange } = this.props;

    let newValue = new AsYouType('US').input(value);
    if (newValue[newValue.length - 1] === ')') {
      newValue = newValue.replace(/[()]/g, '');
    }

    onChange(name, newValue);
  }

  render() {
    const {
      name,
      value,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      ...otherProps
    } = this.props;
    return (
      <Form.Item
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        required={required}
        {...errorProps(errors, name)}
      >
        <Input
          name={name}
          // Note: Placeholder will only appear if value is undefined
          value={value || undefined}
          placeholder={placeholder || label}
          onChange={this.handleChange}
          {...otherProps}
        />
      </Form.Item>
    );
  }
}

export default PhoneInput;
