import React, { useCallback, useMemo } from 'react';
import { notification } from 'antd';

const AlertContext = React.createContext();

/**
 * This class serves as a wrapper for the AlertContext.Provider.
 * this will provide all consumers with a function to render a notification.
 */
export const AlertProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const show = useCallback((message, options = {}) => {
    const notify = ((type) => {
      switch (type) {
        case 'info':
          return api.info;
        case 'error':
          return api.error;
        case 'warning':
          return api.warning;
        default:
          return api.success;
      }
    })(options.type);

    notify({
      message,
      duration: options.stayOpen ? 0 : 3,
    });
  }, [api]);

  const value = useMemo(() => ({ show }), [show]);

  return (
    <AlertContext.Provider
      value={value}
    >
      {contextHolder}
      {children}
    </AlertContext.Provider>
  );
};

export const AlertConsumer = AlertContext.Consumer;

/**
 * A function to create an HOC component that is a consumer of the AlertContext
 *
 * This will cause the component to have a property of `alert` passed in that can
 * be used to call the `show` function to create an alert from anywhere
 *
 * @param {*} ChildComponent The component to wrap
 */
export const withAlert = (ChildComponent) => (
  function WrapperComponent(props) {
    return (
      // Render the context consumer
      // And render the child component with its usual props, as well as the alert prop
      <AlertContext.Consumer>
        { (value) => <ChildComponent {...props} alert={value} /> }
      </AlertContext.Consumer>
    );
  }
);

export default AlertContext;
