import { FormattedMessage } from 'react-intl';
import React, { Component } from 'react';
import { Form, Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';
import withImageUploadProps from './util/withImageUploadProps';

class MultiImageUploader extends Component {
  constructor(props) {
    super(props);

    // Build original file list from the values passed in
    const { images = [], value = [] } = props;
    const fileList = [];
    value.forEach((id) => {
      const image = images.find((curImage) => curImage._id === id) || {};

      fileList.push({
        uid: id,
        _id: id,
        status: 'done',
        url: image.sizes.thumb,
        full: image.url,
      });
    });

    this.state = {
      // Stores the state of uploaded images
      fileList,
      previewVisible: false,
      previewUrl: undefined,
    };

    this.handleUploadingListChange = this.handleUploadingListChange.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.onPreviewClose = this.onPreviewClose.bind(this);
  }

  /**
   * Updates `fileList` state variable based on the changed ids in the `value` prop
   */
  componentDidUpdate(prevProps) {
    const { images, value } = this.props;
    const { fileList } = this.state;

    const valueChanged = value !== prevProps.value && !_.isEqual(value, prevProps.value);

    if (valueChanged) {
      const newFileList = fileList;
      value.forEach((id) => {
        // If the id is already in the internal list of images, do nothing
        const existingImage = fileList.find((file) => file._id === id);
        if (existingImage) {
          return;
        }

        // id is a new value, add it from images
        const image = images.find((curImage) => curImage._id === id) || {};

        newFileList.push({
          uid: id,
          _id: id,
          status: 'done',
          url: image.sizes.thumb,
          full: image.url,
        });
      });

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ fileList: newFileList });
    }
  }

  onPreview(file) {
    this.setState({
      previewUrl: file.full,
      previewVisible: true,
    });
  }

  onPreviewClose() {
    this.setState({
      previewUrl: undefined,
      previewVisible: false,
    });
  }

  /**
   * updates the `fileList` state variable with the current state of images
   */
  handleUploadingListChange({ fileList }) {
    const { onChange, name } = this.props;

    const newFileList = [];
    fileList.forEach((originalFile) => {
      const file = { ...originalFile };
      if (file.status !== 'uploading' && file.status !== 'done') {
        return;
      }

      // If the file was uploaded in the current scope, set id and url
      if (file.response && !file._id && !file.url && !file.full) {
        const { image } = file.response;
        file._id = image._id;
        file.url = image.sizes.thumb;
        file.full = image.url;
      }

      newFileList.push(file);
    });

    this.setState({ fileList: newFileList });

    if (onChange) {
      const ids = [];
      newFileList.forEach((file) => {
        if (file._id) {
          ids.push(file._id);
        }
      });
      onChange(name, ids);
    }
  }

  render() {
    const {
      name,
      textArea,
      value,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      route,
      customRequest,
      beforeUpload,
      error,
      ...otherProps
    } = this.props;

    const {
      fileList,
      previewUrl,
      previewVisible,
    } = this.state;

    return (
      <>
        <Form.Item
          label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
          required={required}
          {...errorProps(errors, name)}
          {...(error ? { validateStatus: 'error', help: error } : (errorProps(errors, name)))}
        >
          <Upload
            listType="picture-card"
            action={route}
            beforeUpload={beforeUpload}
            fileList={fileList}
            onChange={this.handleUploadingListChange}
            customRequest={customRequest}
            onPreview={this.onPreview}
            multiple
            {...otherProps}
          >
            <div>
              <PlusOutlined />
              <div className="ant-upload-text">
                <FormattedMessage defaultMessage="Upload" />
              </div>
            </div>
          </Upload>
        </Form.Item>

        <Modal
          visible={previewVisible}
          footer={null}
          bodyStyle={{ padding: 0 }}
          onCancel={this.onPreviewClose}
        >
          <img alt="example" style={{ width: '100%' }} src={previewUrl} />
        </Modal>
      </>
    );
  }
}

export default withImageUploadProps(MultiImageUploader);
