import { getYear } from 'date-fns';
import React from 'react';
import { formatDate } from '../../../../utils/format';

const DATE_FORMAT_NO_DAY = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

const DATE_FORMAT_NO_YEAR = {
  month: 'short',
  day: 'numeric',
};

const DateRange = ({ startDate, endDate }) => {
  const startYear = getYear(startDate);
  const endYear = getYear(endDate);

  let dateString = '';

  if (startYear === endYear) {
    dateString = `${formatDate(startDate, DATE_FORMAT_NO_YEAR)} - ${formatDate(endDate, DATE_FORMAT_NO_DAY)}`;
  } else {
    dateString = `${formatDate(startDate, DATE_FORMAT_NO_DAY)} - ${formatDate(endDate, DATE_FORMAT_NO_DAY)}`;
  }

  return (
    <>
      {dateString}
    </>
  );
};

export default DateRange;
