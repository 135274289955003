import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { CURRENCIES } from '../../../../config/constants/constants';

const Money = ({
  value, currency, rounded = false, short = false,
}) => {
  let translationKey = short ? 'money.display_short' : 'money.display';
  let displayValue = value;
  if (value < 0) {
    translationKey += '.negative';
    displayValue *= -1;
  }

  const messages = defineMessages({
    'money.display': { defaultMessage: '{symbol}{value} {currency}' },
    'money.display.negative': { defaultMessage: '- {symbol}{value} {currency}' },
    'money.display_short': { defaultMessage: '{symbol}{value}' },
    'money.display_short.negative': { defaultMessage: '- {symbol}{value}' },
  });

  return (
    <FormattedMessage
      {...messages[translationKey]}
      values={
        {
          currency: currency || '',
          symbol: CURRENCIES[currency] ? CURRENCIES[currency].symbol : CURRENCIES.default.symbol,
          value: rounded ? Math.ceil(displayValue)
            : displayValue.toFixed(CURRENCIES[currency] && CURRENCIES[currency].decimals
              ? CURRENCIES[currency].decimals
              : CURRENCIES.default.decimals) || 0,
        }
      }
    />
  );
};

export default Money;
