import { defineMessages } from 'react-intl';

import TIME_ZONES from './timezones';

// ex: Mon Jan 1st, 2021
export const DATE_FORMAT = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};
// ex: 2021-01-01
export const DATE_FORMAT_ISO = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
// ex: Mon Jan 1st, 2021 1:05:59 AM
export const DATETIME_FORMAT = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
};
// ex: Mon Jan 1st, 2021 1:05:59 AM CST
export const DATETIME_TZ_FORMAT = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
};
// ex: Mon Jan 1st, 2021 1:05:59 AM UTC
export const DATETIME_UTC_FORMAT = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
  timeZone: 'UTC',
};
// ex: 2021-01-01 23:01:59
export const DATETIME_FORMAT_COMPACT = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
};
// ex: 1:05 AM
export const TIME_FORMAT = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
};

// ex: 1:05 AM CST
export const TIME_TZ_FORMAT = {
  hour: 'numeric',
  minute: '2-digit',
  hour12: true,
  timeZoneName: 'short',
};

// ex: September 19, 2022
export const SHORT_DATE_FORMAT = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};

// ex: Sep 19, 2022
export const SHORTER_DATE_FORMAT = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

// Ex: September 19
export const NO_YEAR_DATE_FORMAT = {
  month: 'long',
  day: 'numeric',
};

// Ex: Mon, Sep 19
export const NO_YEAR_WITH_DAY_DATE_FORMAT = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};

export const DEFAULT_TIMEZONE = 'UTC';

export const SASKATCHEWAN_TIME_ZONES = [
  'America/Regina',
  'America/Swift_Current',
  'Canada/Saskatchewan',
];

// Used for parsing dates. Ex: 23:01:01.000
export const TIME_PARSE_FORMAT = 'HH:mm:ss.SSS';

export const TRANSACTION_TYPE_VALUES = {
  PAYMENT: 'payment',
  REFUND: 'refund',
};

export const TRANSACTION_TYPE_MESSAGES = defineMessages({
  payment: { defaultMessage: 'Payment' },
  refund: { defaultMessage: 'Refund' },
});

export const TRANSACTION_TYPES = [{
  value: 'payment',
  translation: TRANSACTION_TYPE_MESSAGES.payment,
},
{
  value: 'refund',
  translation: TRANSACTION_TYPE_MESSAGES.refund,
}];

export const APPLY_TO_TYPES = {
  BOOKING: 'Booking',
  SALE: 'Sale',
  EVENT_REGISTRATION: 'EventRegistration',
};

export const ALLOWABLE_FEATURES = ['advancedFees'];

// Note: If you are updating this value, update it in the backend project as well
export const MAX_IMAGE_SIZE = { bytes: 10485760, mb: 10 };

export const DISTANCE_UNITS_MESSAGES = defineMessages({
  ft: { defaultMessage: 'Feet' },
  m: { defaultMessage: 'Meters' },
});

export const DISTANCE_UNITS = [
  {
    value: 'ft',
    translation: DISTANCE_UNITS_MESSAGES.ft,
  },
  {
    value: 'm',
    translation: DISTANCE_UNITS_MESSAGES.m,
  },
];

export const DISTANCE_UNIT_FT = DISTANCE_UNITS[0].value;

export const DISTANCE_UNIT_M = DISTANCE_UNITS[1].value;

/*
 *  CurrencyCode: { symbol: '$', [decimals: 2] } //decimals is optional
 */
export const CURRENCIES = {
  CAD: { symbol: '$' },
  USD: { symbol: '$' },
  GBP: { symbol: '£' },
  EUR: { symbol: '€' },
  default: { symbol: '¤', decimals: 2 },
};

export const CREDIT_CARD_FORMATS = {
  'American Express': '**** ****** *',
  'Diners Club': '**** ****** ',
  Visa: '**** **** **** ',
  MasterCard: '**** **** **** ',
  Discover: '**** **** **** ',
  JCB: '**** **** **** ',
  UnionPay: '**** **** **** ',
  Unknown: '**** **** **** ',
};

export { TIME_ZONES };

export const SOCIAL_LINKS = {
  twitter: 'https://twitter.com/letscampapp',
  facebook: 'https://www.facebook.com/campgroundreservationsystem/',
  instagram: 'https://instagram.com/letscampapp',
  tiktok: 'https://vm.tiktok.com/ZMLdQ3uqM/',
  youtube: 'https://www.youtube.com/channel/UC8DpQvY86z9zQi7j8xkLbwA',
};

export const FOOTER_LINKS = {
  blog: 'https://blog.letscamp.ca',
  faq: 'https://support.letscamp.ca/camper-faq/',
  about: 'https://join.letscamp.ca/about/',
  campblog: 'https://blog.letscamp.ca/home/campground-owner-blogs/',
  campsupport: 'https://support.letscamp.ca/campground-support/',
};

export const MIN_NIGHTS_HELP_LINK = 'https://support.letscamp.ca/knowledge-base/season-set-up/';

export const MIME_TYPES = {
  XLSX: 'application/vnd.ms-excel',
  PDF: 'application/pdf',
};

export const LOGOUT_URL = '/login';

export const EMAIL_MARKDOWN_FORMATTING_OPTIONS = [
  ['bold', 'italic', 'link'],
];

export const DEFAULT_MARKDOWN_FORMATTING_OPTIONS = [
  ['header', 'bold', 'italic', 'strikethrough'],
  ['link', 'quote', 'image'],
  ['unordered-list', 'ordered-list', 'checked-list'],
];

export const COOKIE_CONSENT_EXPIRY_MONTHS = 6;

export {
  GETALPHA2CODE,
  COUNTRIES,
  COUNTRY_CODE_CANADA,
  COUNTRY_CODE_US,
} from './countries';
