/**
 * This file is responsbile for defining the stripe config and providing utilities for interacting with stripe
 */

import { loadStripe } from '@stripe/stripe-js';

const stripe = loadStripe(window.ENV.REACT_APP_STRIPE_API_KEY);

export default stripe;

export const convertStripeError = (error, intl) => {
  const errors = {};
  if (error.code === 'card_declined') {
    const err = { message: intl.formatMessage({ defaultMessage: 'Your card was declined' }) };
    errors.cardNumber = err;
    errors.paymentMethodId = err;
  } else if (error.code === 'expired_card') {
    const err = { message: intl.formatMessage({ defaultMessage: 'Your card is expired' }) };
    errors.cardNumber = err;
    errors.paymentMethodId = err;
  } else if (error.code === 'payment_intent_incompatible_payment_method') {
    errors.paymentMethodId = { message: intl.formatMessage({ defaultMessage: 'Payment method is invalid' }) };
  } else if (error.param) {
    switch (error.param) {
      case 'payment_method_data[billing_details][address][line1]':
        errors.address = { message: intl.formatMessage({ defaultMessage: 'Address is invalid' }) };
        break;
      case 'payment_method_data[billing_details][address][city]':
        errors.city = { message: intl.formatMessage({ defaultMessage: 'City is invalid' }) };
        break;
      case 'payment_method_data[billing_details][address][state]':
        errors.province = { message: intl.formatMessage({ defaultMessage: 'State/Province is invalid' }) };
        break;
      case 'payment_method_data[billing_details][address][country]':
        errors.country = { message: intl.formatMessage({ defaultMessage: 'Country is invalid' }) };
        break;
      case 'payment_method_data[billing_details][address][postal_code]':
        errors.postalCode = { message: intl.formatMessage({ defaultMessage: 'Zip/Postal Code is invalid' }) };
        break;
      case 'payment_method_data[billing_details][name]':
        errors.name = { message: intl.formatMessage({ defaultMessage: 'Name is invalid' }) };
        break;
      case 'cvc':
        errors.securityCode = { message: intl.formatMessage({ defaultMessage: 'Security code is invalid' }) };
        break;
      default: {
        const err = { message: intl.formatMessage({ defaultMessage: 'There was an unexpected error processing your transaction.' }) };
        errors.cardNumber = err;
        errors.paymentMethodId = err;
        break;
      }
    }
  } else {
    const err = { message: intl.formatMessage({ defaultMessage: 'There was an unexpected error processing your transaction.' }) };
    errors.cardNumber = err;
    errors.paymentMethodId = err;
  }

  return errors;
};
