import React, { Component } from 'react';
import {
  Switch as AntdSwitch, Form, Row, Col,
} from 'antd';
import { errorProps } from '../../../../utils/errors';
import style from './Switch.module.less';
import LabelColumn from './helpers/LabelColumn';
import Label from './Label';

class Switch extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // Handle change for text input
  handleChange(value) {
    const { onChange, name } = this.props;

    onChange(name, value);
  }

  render() {
    const {
      name,
      value,
      label,
      errors,
      onChange,
      tooltip,
      labelPosition = 'right',
      additionalInfo,
      className = [],
      ...otherProps
    } = this.props;

    const justify = (() => {
      if (labelPosition === 'left') {
        return 'space-between';
      }
      if (labelPosition === 'right') {
        return 'start';
      }
      return undefined;
    })();

    return (
      <Form.Item
        {...errorProps(errors, name)}
        label={labelPosition === 'top' && label ? <Label text={label} tooltip={tooltip} /> : undefined}
        className={[...className, style.switch].join(' ')}
      >
        <Row justify={justify}>
          {
            labelPosition === 'left'
              && label ? (
                <LabelColumn label={label} tooltip={tooltip} additionalInfo={additionalInfo} />
              ) : null
          }
          <Col>
            <AntdSwitch
              name={name}
              checked={value}
              onChange={this.handleChange}
              {...otherProps}
            />
          </Col>
          {
            labelPosition === 'right'
              && label ? (
                <LabelColumn label={label} tooltip={tooltip} additionalInfo={additionalInfo} />
              ) : null
          }
        </Row>
      </Form.Item>
    );
  }
}

export default Switch;
