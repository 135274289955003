import React, { Component } from 'react';
import { setYear, setMonth, subMonths } from 'date-fns';
import DatePicker from './DatePicker';

class MonthPicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(_, dateString) {
    const { yearName, monthName, onChange } = this.props;
    let year = null;
    let month = null;
    if (dateString && typeof dateString === 'string') {
      [year, month] = dateString.split('-');
    }
    onChange({ [yearName]: year, [monthName]: month });
  }

  render() {
    const {
      yearName,
      monthName,
      monthValue,
      yearValue,
      ...otherProps
    } = this.props;

    let displayValue;

    if (monthValue && yearValue) {
      // Set month & year, then subtract 1 month becase months are 0 indexed
      displayValue = subMonths(setMonth(setYear(new Date(0), yearValue), monthValue), 1);
    }

    return (
      <DatePicker
        {...otherProps}
        picker="month"
        yearName={yearName}
        monthName={monthName}
        onChange={this.handleChange}
        value={displayValue}
      />
    );
  }
}

export default MonthPicker;
