/**
 * This file is responsible for checking if the user has provided consent for cookies
 */

import { isBefore } from 'date-fns';

/**
 * Retrieve the cookie consent from session storage
 * @returns The cookie consent status
 */
const getCookieConsent = () => {
  const stored = localStorage.getItem('cookieConsent');
  if (!stored) {
    return null;
  }

  const cookieConsent = JSON.parse(stored);

  // If the current consent is expired, return null
  if (isBefore(new Date(cookieConsent.expiry), new Date())) {
    return null;
  }

  return cookieConsent;
};

export default getCookieConsent;
