import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { MAX_IMAGE_SIZE } from '../../../../../config/constants/constants';

const withImageUploadProps = (WrappedComponent) => {
  const ImageUploadHOC = ({ intl, paramName, ...props }) => {
    const [errorMessage, setErrorMessage] = useState();

    /**
     * Overrides the default XHR request used by the upload component
     */
    const customRequest = useCallback((options) => {
      const {
        data, withCredentials, headers, file,
      } = options;

      const formData = new FormData();
      formData.append(paramName || 'image', file);

      if (data) {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
      }

      axios.post(options.action, formData, {
        withCredentials,
        // Set content type to a format that allows files
        headers: { 'Content-Type': 'multipart/form-data', ...headers },
        onUploadProgress: (event) => {
          options.onProgress({
            percent: Math.round((event.loaded * 100) / event.total).toFixed(2),
          }, file);
        },
      })
        .then((response) => {
          options.onSuccess(response.data, file);
        })
        .catch((error) => {
          options.onError(error);
          if (error.response && error.response.data) {
            const { data: resData } = error.response;
            if (resData.errors) {
              setErrorMessage(resData.errors[paramName || 'image'].message);
            }
          }
        });
    }, [paramName]);

    /**
     * Validates the uploaded file on the client side first
     */
    const beforeUpload = useCallback((file) => {
      if (file.size > MAX_IMAGE_SIZE.bytes) {
        setErrorMessage(
          intl.formatMessage(
            {
              defaultMessage: '{field} is too large. Maximum {max} MB',
            },
            {
              field: intl.formatMessage({ defaultMessage: 'Image' }),
              max: MAX_IMAGE_SIZE.mb,
            },
          ),
        );
        return false;
      }

      return true;
    }, [intl]);

    return (
      <WrappedComponent
        {...props}
        error={errorMessage}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
      />
    );
  };

  return injectIntl(ImageUploadHOC);
};

export default withImageUploadProps;
