import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingCart,
} from '@fortawesome/pro-solid-svg-icons';
import CartContext from '../../../common/contexts/CartContext';
import style from './CartSummary.module.less';
import { Money } from '../../../common/components/formatting';
import CartTimer from './CartTimer';
import { clientPortalRoute } from '../../../client/helpers/clientLinkHelper';

const CartSummary = ({ admin = false, noTimer = false, ...props }) => {
  const { className, style: importedStyle } = props;
  const { cart, effectiveLockedUntil } = useContext(CartContext);
  return (
    <>
      <Link to={admin ? clientPortalRoute('/cart') : '/profile/cart'} key="cart" className={className} style={importedStyle}>
        <span className={style.cart}>
          {
            cart?.totals && Object.keys(cart.totals).length === 1 ? (
              <Money
                value={cart.totals[Object.keys(cart.totals)[0]]}
                currency={Object.keys(cart.totals)[0]}
              />
            ) : null
          }
          <FontAwesomeIcon icon={faShoppingCart} className={style.icon} />
          {
            cart?.numItems ? (
              <span className={style.numItems}>{cart.numItems <= 9 ? cart.numItems : '9+'}</span>
            ) : null
          }
        </span>
      </Link>
      {
        !noTimer && cart?.lockedUntil && cart?.numItems ? (
          <div className={[style.timer, admin ? style.admin : ''].join(' ')}>
            <CartTimer lockedUntil={effectiveLockedUntil} />
          </div>
        ) : null
      }
    </>
  );
};

export default CartSummary;
