import { getImpersonatedId } from '../../../utils/impersonateClient';
import { getActiveCampId, getActiveCampSlug } from '../utils/activeCamp';

/**
 * Replaces the client ID with the slug if needed
 * @param {String} link The link to go to
 * @param {String} campId The client's Camp ID
 * @param {String} slug The camp slug
 *
 * @returns The link
 */
const clientLinkHelper = (link, campId, slug) => {
  const newLink = link.replace(/^\//, `/client/${slug || campId}/`);
  return newLink;
};

/**
 * Builds a link path to the given route and appends the client ID or slug
 * @param {String} link The client portal link
 * @param {Boolean} useSlug Whethere to use the client's slug or ID
 *
 * @returns The built link
 */
const clientLinkPath = (link, useSlug = false) => {
  const campId = getActiveCampId();
  const campSlug = getActiveCampSlug();
  return clientLinkHelper(link, campId, useSlug ? campSlug : null);
};

/**
 * Builds a route to the client portal, and appends impersonating if necessary
 * @param {String} link The client route you want to go to
 * @param {String} query A stringified query string to append
 *
 * @returns The built client route
 */
export const clientPortalRoute = (link, query) => {
  let newPath = clientLinkPath(link, true);
  newPath = `${newPath}${query ? `?${query}` : ''}`;
  const impersonatedClientId = getImpersonatedId();
  if (impersonatedClientId) {
    const url = new URL(`${window.location.origin}${newPath}`);
    url.searchParams.append('impersonating', impersonatedClientId);
    newPath = `${url.pathname}${url.search}`;
  }
  return newPath;
};

export const clientPortalApiRoute = (link) => (
  clientLinkPath(link)
);
