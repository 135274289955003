import React, { Fragment } from 'react';
import { Menu, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import { Can } from '../contexts/AbilityContext';

const NavLink = (props) => {
  const {
    route, children, can, disableIfCannot, externalLink = false, ...otherProps
  } = props;

  let Wrapper = Fragment;
  let cannotWrapper;
  if (can && can.I && (can.a || can.this)) {
    Wrapper = ({ children: canChildren }) => (
      <Can I={can.I} a={can.a || can.this} field={can.field}>
        {canChildren}
      </Can>
    );
    if (disableIfCannot) {
      cannotWrapper = (
        <Can not I={can.I} a={can.a || can.this}>
          <Tooltip placement="right" title={disableIfCannot}>
            <div>
              <Menu.Item disabled key={route} {...otherProps}>
                {children}
                <InfoCircleOutlined style={{ marginLeft: 8 }} />
              </Menu.Item>
            </div>
          </Tooltip>
        </Can>
      );
    }
  }
  if (externalLink) {
    return (
      <>
        <Wrapper>
          <Menu.Item key={route} {...otherProps}>
            <a href={route} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          </Menu.Item>
        </Wrapper>
        {cannotWrapper}
      </>
    );
  }
  return (
    <>
      <Wrapper>
        <Menu.Item key={route} {...otherProps}>
          <Link to={route}>
            {children}
          </Link>
        </Menu.Item>
      </Wrapper>
      {cannotWrapper}
    </>
  );
};

export default NavLink;
