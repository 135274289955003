import React, { useState, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Row, Col, Form, Input, Select, Button,
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { errorProps } from '../../../../utils/errors';
import style from './MultiUnitTypePicker.module.less';
import { DISTANCE_UNIT_FT } from '../../../../config/constants/constants';
import {
  UNIT_TYPES, BOAT_UNIT_TYPES, SINGLE_UNIT_TYPES, NON_SINGLE_UNIT_TYPES,
  UNIT_TYPE_TENT, UNIT_TYPE_RV, ALL_UNIT_TYPES, UNIT_TYPE_BOAT,
} from '../../../../config/constants/booking';
import MeasurementInput from './MeasurementInput';

const { Option } = Select;

const determineAllowedUnitTypes = (value, { allowBoats, allowedUnitTypes }) => {
  let availableUnitTypes;

  // If allowedUnitTypes is passed in, ignore allowBoats
  if (allowedUnitTypes) {
    availableUnitTypes = ALL_UNIT_TYPES.filter(
      (unitType) => allowedUnitTypes
        .includes(unitType.value),
    );
  } else {
    availableUnitTypes = allowBoats ? BOAT_UNIT_TYPES : UNIT_TYPES;
  }

  // If there is a value, filter it futher down to any non single unit types
  if (value && value.length && (value.length > 1)) {
    availableUnitTypes = NON_SINGLE_UNIT_TYPES.filter(
      (unitType) => availableUnitTypes
        .map((allowedUnitType) => allowedUnitType.value)
        .includes(unitType.value),
    );
  }

  return availableUnitTypes;
};

const MultiUnitTypePicker = ({
  name, value, onChange, label, errors, required, allowBoats, allowedUnitTypes,
  hideDisabledUnitTypes,
}) => {
  const [
    availableUnitTypes, setAvailableUnitTypes,
  ] = useState(determineAllowedUnitTypes(value, { allowBoats, allowedUnitTypes }));

  useEffect(() => {
    setAvailableUnitTypes(
      determineAllowedUnitTypes(value, { allowBoats, allowedUnitTypes }),
    );
  }, [value, setAvailableUnitTypes, allowBoats, allowedUnitTypes]);

  const hasRvs = value.filter(
    (unitType) => unitType.unitType === UNIT_TYPE_RV,
  ).length >= 1;

  const hasBoats = value.filter(
    (unitType) => unitType.unitType === UNIT_TYPE_BOAT,
  ).length >= 1;

  const hasRvsOrBoats = hasRvs || hasBoats;

  const rvIndex = value.findIndex(
    (unitType) => unitType.unitType === UNIT_TYPE_RV || unitType.unitType === UNIT_TYPE_BOAT,
  );

  const rvLength = value[rvIndex] && value[rvIndex].length
    ? { amount: value[rvIndex].length, unit: value[rvIndex].measurementUnit }
    : { amount: undefined, unit: DISTANCE_UNIT_FT };

  const handleUnitTypesChange = (index, newValue) => {
    const unitTypeList = [...value];
    if (newValue === UNIT_TYPE_RV) {
      unitTypeList[index] = {
        unitType: newValue,
        length: rvLength.amount,
        measurementUnit: rvLength.unit,
      };
    } else {
      unitTypeList[index] = { unitType: newValue, measurementUnit: DISTANCE_UNIT_FT };
    }

    onChange(name, unitTypeList);
  };

  const handleRvLengthChange = (newName, newValue) => {
    const fieldName = _.last(newName.split('.'));

    const newUnitTypes = [...value];

    newUnitTypes.forEach(
      (unitType, index) => {
        if (unitType.unitType === UNIT_TYPE_RV || unitType.unitType === UNIT_TYPE_BOAT) {
          if (fieldName === 'amount') {
            newUnitTypes[index] = {
              ...newUnitTypes[index],
              length: newValue,
            };
          }
          if (fieldName === 'unit') {
            newUnitTypes[index] = {
              ...newUnitTypes[index],
              measurementUnit: newValue,
            };
          }
        }
      },
    );

    onChange(name, newUnitTypes);
  };

  const handleAddUnitType = useCallback(() => {
    const newValue = [...value, {
      uid: _.uniqueId('unitType_'),
      unitType: hasBoats ? UNIT_TYPE_BOAT : UNIT_TYPE_TENT,
    }];

    onChange(name, newValue);
  }, [value, name, onChange, hasBoats]);

  const handleRemoveUnitType = (index) => {
    // Remove unitType at index
    value.splice(index, 1);

    onChange(name, value);
  };

  const intl = useIntl();

  return (
    <>
      <Form.Item
        required={required}
        label={label || null}
      >
        {
          value.length
            ? value.map((unitType, index) => (
              <Row key={unitType.uid || `unitType_${index}`} type="flex" align="bottom" style={{ flexWrap: 'nowrap' }}>
                <Col className="fill">
                  <Form.Item
                    required={required}
                    {...errorProps(errors, `${name}.${index}.unitType`)}
                  >
                    <Input.Group compact>
                      <Select
                        name={`${name}.${index}.unitType`}
                        style={{ width: '100%' }}
                        required={required}
                        placeholder={<FormattedMessage defaultMessage="Unit Type" />}
                        value={unitType.unitType}
                        onChange={(newValue) => handleUnitTypesChange(index, newValue)}
                      >
                        {
                          (allowBoats ? BOAT_UNIT_TYPES : UNIT_TYPES).map(
                            ({ value: unitTypeValue, translation: unitTypeTranslation }) => {
                              const disabled = !availableUnitTypes.some(
                                (type) => type.value === unitTypeValue,
                              );
                              if (hideDisabledUnitTypes && disabled) {
                                return null;
                              }
                              return (
                                <Option
                                  value={unitTypeValue}
                                  key={unitTypeValue}
                                  disabled={disabled}
                                >
                                  <FormattedMessage {...unitTypeTranslation} />
                                </Option>
                              );
                            },
                          )
                        }
                      </Select>
                    </Input.Group>
                  </Form.Item>
                </Col>
                {
                  value.length > 1
                    ? (
                      <Col>
                        <MinusCircleOutlined
                          className={style.deleteButton}
                          onClick={() => handleRemoveUnitType(index)}
                        />
                      </Col>
                    ) : (
                      null
                    )
                }
              </Row>
            )) : null
          }
        {
          !SINGLE_UNIT_TYPES.includes(value[0].unitType)
            ? (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={handleAddUnitType}
                >
                  <PlusOutlined /> <FormattedMessage defaultMessage="Add Additional Unit" />
                </Button>
              </Form.Item>
            ) : (
              null
            )
        }
      </Form.Item>
      {
        hasRvsOrBoats ? (
          <MeasurementInput
            name={`${name}.${rvIndex}.length`}
            label={intl.formatMessage({ defaultMessage: '{type} Length' }, { type: hasRvs ? intl.formatMessage({ defaultMessage: 'RV' }) : intl.formatMessage({ defaultMessage: 'Boat' }) })}
            value={rvLength}
            errors={
              {
                [`${name}.${rvIndex}.length.amount`]: errors ? errors[`${name}.${rvIndex}.length`] : undefined,
                [`${name}.${rvIndex}.length.unit`]: errors ? errors[`${name}.${rvIndex}.measurementUnit`] : undefined,
              }
            }
            required={required}
            onChange={handleRvLengthChange}
          />
        ) : (
          null
        )
      }
    </>
  );
};

export default MultiUnitTypePicker;
