import React, { Component } from 'react';
import axios from 'axios';
import ability from '../../../utils/ability';
import setAuthToken from '../../../utils/setAuthToken';

const UserContext = React.createContext();
window.ability = ability;
const setRules = (rules) => {
  // Store the user's original rules, in case we need to temporarily override rules
  ability.originalRules = rules;

  // If there is currently a temporary ruleset on the ability, don't update
  if (!ability.temporary) {
    ability.update(rules);
  }
};

/**
 * This class serves as a wrapper for the UserContext.Provider.
 */
export class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loaded: false,
    };

    this.setUser = this.setUser.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken');

    if (token) {
      // Retrieve user info if the user is logged in
      axios.get('/profile')
        .then((response) => {
          if (response && response.data) {
            const { rules } = response.data;

            setRules(rules);

            this.setState({ user: response.data.user });
          }
        }).catch(() => {})
        .finally(() => {
          this.setState({ loaded: true });
        });
    } else {
      this.setState({ loaded: true });
    }
  }

  setUser(user) {
    this.setState({
      user,
    });
  }

  removeUser() {
    this.setUser(null);
  }

  login(data) {
    const { token, user, rules } = data;

    // Store user in context
    this.setUser(user);
    // Store the users Auth token
    setAuthToken(token);
    // Update the users permission
    setRules(rules);
  }

  logout() {
    // Remove user from context
    this.removeUser();
    // Remove auth token from localStorage
    setAuthToken(false);
    // Remove users permissions
    ability.update([]);
  }

  render() {
    const { children } = this.props;
    const { user, loaded } = this.state;

    return (
      <UserContext.Provider
        value={
          {
            user,
            loaded,
            login: this.login,
            logout: this.logout,
          }
        }
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;

/**
 * A function to create an HOC component that is a consumer of the AlertContext
 *
 * This will cause the component to have a property of `userContext` passed in,
 * so any component can get access to the logged in user.
 * One example would be to display the users name
 *
 * @param {*} ChildComponent The component to wrap
 */
export const withUser = (ChildComponent) => (
  function WrapperComponent(props) {
    return (
      // Render the context consumer
      // And render the child component with its usual props, as well as the user prop
      <UserContext.Consumer>
        { (value) => <ChildComponent {...props} userContext={value} /> }
      </UserContext.Consumer>
    );
  }
);

export default UserContext;
