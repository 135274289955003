import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { COUNTRY_CODE_CANADA, COUNTRY_CODE_US } from '../../../../config/constants/constants';
import { PROVINCE_MESSAGES_BY_COUNTRY } from '../../../../config/constants/provinces';
import Input from './Input';
import Select from './Select';

const ProvinceInput = (props) => {
  const {
    country,
    name,
    value,
    placeholder,
    errors,
    onChange,
    required,
    tooltip,
    label,
  } = props;

  const intl = useIntl();

  const prevCountryRef = useRef(country);
  useEffect(() => {
    prevCountryRef.current = country;
  });
  const prevCountry = prevCountryRef.current;

  // If the current province value is not in one of the approved values it should clear
  useEffect(() => {
    if (
      [COUNTRY_CODE_CANADA, COUNTRY_CODE_US].includes(country)
      && !PROVINCE_MESSAGES_BY_COUNTRY[country][value]
    ) {
      onChange(name, null);
    }
  }, [country, value, onChange, name, prevCountry]);


  if ([COUNTRY_CODE_CANADA, COUNTRY_CODE_US].includes(country)) {
    return (
      <Select
        name={name}
        value={value}
        placeholder={placeholder}
        errors={errors}
        onChange={onChange}
        required={required}
        tooltip={tooltip}
        label={label}
        showSearch
        filterOption
        optionFilterProp="children"
      >
        {
          (() => (
            _.map(PROVINCE_MESSAGES_BY_COUNTRY[country], (translation, provinceValue) => (
              <Select.Option key={provinceValue} value={provinceValue}>
                {intl.formatMessage(translation)}
              </Select.Option>
            ))
          ))()
        }
      </Select>
    );
  }

  return (
    <Input
      name={name}
      value={value}
      placeholder={placeholder}
      errors={errors}
      onChange={onChange}
      required={required}
      tooltip={tooltip}
      label={label}
    />
  );
};

export default ProvinceInput;
