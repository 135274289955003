/**
 * This file is responsible for defining functions to properly format dates according to the users locale
 */
import { intlFormat, parseISO, parse } from 'date-fns';
import { createIntl, createIntlCache } from 'react-intl';
import {
  DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT, TIME_PARSE_FORMAT,
  SASKATCHEWAN_TIME_ZONES,
} from '../config/constants/constants';
import { appLocale } from '../config/setupConfig';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const intl = createIntl({
  locale: appLocale.locale,
  messages: appLocale.messages,
  defaultLocale: appLocale.locale,
}, cache);

/**
 * Takes in a formatted date or time string, and converts Saskatchewan time zones if applicable
 * @param {*} formattedDateOrTime The formatted date or time string
 * @param {*} dateFormat The date format object
 * @returns The updated date or time string with Saskatchewan time zone overridden
 */
const overrideTimezone = (formattedDateOrTime, dateFormat) => {
  let overriddenDateOrTime = formattedDateOrTime;
  if (dateFormat.timeZoneName && SASKATCHEWAN_TIME_ZONES.find(
    (timezone) => timezone.toLowerCase() === Intl.DateTimeFormat().resolvedOptions()
      .timeZone.toLowerCase(),
  )) {
    // Replace the time zone from date-fns with our custom one.
    // Need to use a translated value for the first argument as different languages give
    // the TZ in different formats.
    // EG: French returns UTC-6, German returns GMT-6, English returns CST
    // Canadian French returns HNC which may need to be replaced in future, as it's an acronym for
    // Heure Normale Centrale. Would need to see if that's confusing for french ppl
    overriddenDateOrTime = formattedDateOrTime.replace(
      intl.formatMessage({ defaultMessage: 'CST', description: 'The name of the timezone to be replaced, specifically the abbreviation for Central Standard Time (`CST`). Canadian French would be `HNC`, for example' }),
      intl.formatMessage({ defaultMessage: 'SK Time', description: 'The overridden name of the timezone. Canadian French might be something like `Heure de la Saskatchewan`' }),
    );
  }

  return overriddenDateOrTime;
};

/**
 * Formats a date
 * @param  {} date A date object or date string in an ISO format
 * @param  {} dateFormat=DATE_FORMAT
 *
 * @returns {String} the formatted date
 */
export const formatDate = (date, dateFormat = DATE_FORMAT) => {
  let dateToFormat = date;
  if (typeof date === 'string') {
    dateToFormat = parseISO(date);
  }

  const formattedDate = intlFormat(dateToFormat, dateFormat, { locale: appLocale.locale });

  return overrideTimezone(formattedDate, dateFormat);
};

/**
 * Formats a datetime
 *
 * @param  {} date A date object or date string in an ISO format
 * @param  {} dateFormat=DATE_FORMAT
 *
 * @returns {String} the formatted date
 */
export const formatDateTime = (date, dateFormat = DATETIME_FORMAT) => (
  formatDate(date, dateFormat)
);

/**
 * Formats a time
 * @param  {} date A date object or string in a time format (ex: 14:02:03.004)
 * @param  {} dateFormat=DATE_FORMAT
 *
 * @returns {String} the formatted time
 */
export const formatTime = (date, dateFormat = TIME_FORMAT) => {
  let dateToFormat = date;
  if (typeof date === 'string') {
    dateToFormat = parse(date, TIME_PARSE_FORMAT, 0);
  }
  const formattedTime = intlFormat(dateToFormat, dateFormat, { locale: appLocale.locale });

  return overrideTimezone(formattedTime, dateFormat);
};
