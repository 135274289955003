import React from 'react';
import { TIME_FORMAT, TIME_TZ_FORMAT } from '../../../../config/constants/constants';
import { formatTime } from '../../../../utils/format';

const Time = ({ time, showTimeZone = false }) => (
  <>
    {formatTime(time, showTimeZone ? TIME_TZ_FORMAT : TIME_FORMAT)}
  </>
);

export default Time;
