/**
 * This file is responsible for providing a function that gives us a shortcut/helper to get a camps slug (if defined) or ID
 */

/**
 * returns either the camp's ID or slug (if defined)
 * @param {Object} camp The camp to get the slug for
 * @returns The camp slug (or ID if not defined)
 */
const getCampSlug = (camp) => {
  let slug = null;
  if (camp) {
    slug = camp._id;
    if (camp.info && camp.info.slug) {
      slug = camp.info.slug;
    }
  }
  return slug;
};

export default getCampSlug;
