import React, { useState, useCallback } from 'react';
import { Form } from 'antd';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';

/*
 * Note: These will need to be manually updated if any style variables
 * related to these inputs change.
 */
export const INPUT_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      fontFamily: 'sofia-pro',
      lineHeight: '1.5715',
      color: '#222',
      '::placeholder': {
        color: '#bfbfbf',
      },
    },
  },
};

const CreditCardInput = (props) => {
  const {
    name,
    label,
    errors,
    required,
    tooltip,
    className,
  } = props;

  const [error, setError] = useState(null);

  const handleChange = useCallback((event) => {
    if (event.error) {
      setError({ [name]: { message: event.error.message } });
    } else {
      setError(null);
    }
  }, [name]);

  return (

    <Form.Item
      label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
      required={required}
      {...errorProps(errors, name)}
      {...errorProps(error, name)}
    >
      <CardNumberElement className={`${className || ''} credit-card-input`} options={INPUT_OPTIONS} onChange={handleChange} />
    </Form.Item>

  );
};

export default CreditCardInput;
