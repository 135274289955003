/* eslint-disable global-require */
import { appLocale } from './setupConfig';
/**
 * Export language support for countries based on language code
 */
function langSupport() {
  switch (appLocale.code) {
    case 'en': return require('i18n-iso-countries/langs/en.json');
    case 'fr': return require('i18n-iso-countries/langs/fr.json');
    default: return require('i18n-iso-countries/langs/en.json');
  }
}

export default langSupport();
