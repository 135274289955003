import { useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import HistoryStackContext from './bundles/common/contexts/HistoryStackContext';

const HistoryRecorder = (props) => {
  const { children } = props;

  const historyStack = useContext(HistoryStackContext);

  const history = useHistory();

  const { add } = historyStack;
  const addRef = useRef(add);
  addRef.current = add;

  useEffect(() => {
    if (history.action !== 'REPLACE') {
      addRef.current(history.location);
    }
  }, [history, history.location]);

  return children;
};

export default HistoryRecorder;
