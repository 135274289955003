import React, { Component } from 'react';
import { Switch as AntdSwitch, Form } from 'antd';
import { errorProps } from '../../../../utils/errors';
import style from './SwitchBox.module.less';
import Label from './Label';

class SwitchBox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  // Handle change for text input
  handleChange(checked) {
    const { name, onChange } = this.props;

    onChange(name, checked);
  }

  handleKeyDown(event) {
    const { checked } = this.props;
    // Call handleChange is spacebar is pressed
    if (event.keyCode === 32) {
      // Prevent default event to prevent browser from scrolling down
      event.preventDefault();
      this.handleChange(!checked);
    }
  }

  render() {
    const {
      name,
      checked,
      label,
      placeholder,
      errors,
      onChange,
      required,
      tooltip,
      ...otherProps
    } = this.props;

    return (
      <Form.Item
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        required={required}
        {...errorProps(errors, name)}
      >
        <div
          tabIndex={0}
          className={style.switch}
          onClick={() => this.handleChange(!checked)}
          onKeyDown={this.handleKeyDown}
          role="button"
        >
          <span>
            {placeholder || label}
          </span>
          <AntdSwitch
            tabIndex={-1}
            name={name}
            checked={checked}
            onChange={this.handleChange}
            {...otherProps}
          />
        </div>
      </Form.Item>
    );
  }
}

export default SwitchBox;
