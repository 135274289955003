/**
 * This file is responsible for defining functions that handle server errors returned from the backend,
 * and for displaying validation errors to the user by getting the error message for a given field
 */

import * as Sentry from '@sentry/browser';
import history from './history';
import ability from './ability';

export const errorProps = (errors, key) => {
  if (errors && key) {
    if (errors[key]) {
      return {
        validateStatus: 'error',
        help: errors[key].message,
      };
    }

    // Check if key is nested
    const index = key.indexOf('.');
    if (index >= 0) {
      const currentKey = key.substr(0, index);
      const remainingKey = key.substr(index + 1);
      // If the key is nested, and has sub-errors, dive down to find the desired error
      if (errors[currentKey] && errors[currentKey].errors) {
        return errorProps(errors[currentKey].errors, remainingKey);
      }
    }
  }

  return {};
};

// Logs a 500 error to sentry.io
const captureError = (error) => {
  const responseData = error.response && error.response.data ? error.response.data : undefined;
  const requestData = {};

  if (error.config) {
    requestData.url = error.config.url;
    requestData.data = error.config.data;
    requestData.params = error.config.params;
    requestData.method = error.config.method;
  }

  Sentry.captureException(
    error,
    {
      extra: {
        error: responseData,
        request: requestData,
      },
    },
  );
};

export const handleServerError = (error) => {
  if (error.response) {
    const { status } = error.response;
    if (status !== 400) {
      if (status === 404) {
        history.replace('/404');
      } else if (status === 403) {
        history.replace('/403');
      } else if (status === 401) {
        // Remove the user's token
        localStorage.removeItem('jwtToken');
        // Reset ability
        ability.update([]);

        history.replace('/login');
      } else {
        captureError(error);
        history.replace('/500');
      }
    }
  } else if ('response' in error) {
    captureError(error);
    history.replace('/error');
  }
  // Axios requires Promise.reject(error) to be used
  return Promise.reject(error);
};

/**
 * Loops through errors object, allows errors to be handled
 * like elsewhere by removing 'prefix.' value from keys
 * @param {*} errors errors object
 * @param {*} key Prefix to limit errors to
 */
export const nestedErrorHelper = (errors, prefix) => {
  // Combine key and index passed in
  const totalPrefix = `${prefix}.`;
  // Create object to store relevant errors
  const relErrors = {};

  // Check if errors object is empty
  if (Object.keys(errors).length) {
    // Loop through each key
    Object.keys(errors).forEach((keyVal) => {
      // Check if keyIndex is in keyVal
      if (keyVal.includes(totalPrefix)) {
        // Make new keyVal while removing keyIndex
        const newKeyVal = keyVal.replace(totalPrefix, '');
        // Assign old value to new relErrors obect
        relErrors[newKeyVal] = errors[keyVal];
      }
    });
  }

  return relErrors;
};

/**
 * Loops through errors object, allows errors to be handled
 * like elsewhere by removing 'key.index.' value from keys,
 * and checks if error is relevant to current index
 * @param {*} errors errors object
 * @param {*} index index of current item
 * @param {*} key object type of item
 */
export const arrayErrorHelper = (errors, index, key) => (
  nestedErrorHelper(errors, `${key}.${index}`)
);
