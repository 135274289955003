import React, { useCallback } from 'react';
import { AutoComplete as AntdAutoComplete, Form } from 'antd';
import Label from './Label';
import { errorProps } from '../../../../utils/errors';

const AutoComplete = (props) => {
  const {
    children,
    name,
    value,
    label,
    placeholder,
    errors,
    onChange,
    required,
    tooltip,
    ...otherProps
  } = props;

  const handleChange = useCallback((newValue) => {
    onChange(name, newValue);
  }, [name, onChange]);

  return (
    <Form.Item
      label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
      required={required}
      {...errorProps(errors, name)}
    >
      <AntdAutoComplete
        name={name}
        // Note: Placeholder will only appear if value is undefined
        value={value === null ? undefined : value}
        placeholder={placeholder || label}
        onChange={handleChange}
        {...otherProps}
      >
        {children}
      </AntdAutoComplete>
    </Form.Item>
  );
};

AutoComplete.Option = AntdAutoComplete.Option;

export default AutoComplete;
