/**
 * This file is responsible for setting an authorization token in localStorage so the app knows who is logged in.
 * Also used to clear the token when logging out
 */

const setAuthToken = (token) => {
  if (token) {
    // Set token to local storage
    localStorage.setItem('jwtToken', token);
  } else {
    // Remove token from local storage
    localStorage.removeItem('jwtToken');
  }
};
export default setAuthToken;
