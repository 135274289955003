import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Result, Button } from 'antd';
import Icon from '@ant-design/icons';
import history from '../../../utils/history';
import camper from '../../../icons/errors/camper.svg';

const ServerError = () => (
  <Result
    icon={<Icon component={() => (<img src={camper} alt="500" />)} />}
    title="500"
    subTitle={<FormattedMessage defaultMessage="You've encountered a server error. Please try accessing this page again at a later time." />}
    extra={<Button type="primary" onClick={() => history.push('/')}><FormattedMessage defaultMessage="Go Home" /></Button>}
  />
);

export default ServerError;
