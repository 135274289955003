import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Popover, Button, Form, Divider,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';
import style from './GuestCount.module.less';
import { AGE_RANGE_MESSAGES, AGE_RANGE_DESCRIPTION_MESSAGES } from '../../../../config/constants/booking';

const ageCategories = [
  'infant',
  'youth',
  'adult',
  'senior',
];

class GuestCount extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(name, add) {
    const { onChange, name: parentName, value } = this.props;

    const oldValue = value[name];

    if (!add && oldValue === 0) {
      return;
    }

    const newValue = oldValue + (add ? 1 : -1);

    onChange(`${parentName}.${name}`, newValue);
  }

  render() {
    const {
      name,
      value,
      label,
      errors,
      onChange,
      required,
      tooltip,
      ...otherProps
    } = this.props;

    const content = (
      <>
        {
          value
            ? ageCategories.map((ageRange) => (
              <div key={ageRange}>
                <Form.Item className={style.guestCount}>
                  <div className="label-container">
                    <p className="label"><FormattedMessage {...AGE_RANGE_MESSAGES[ageRange]} /></p>
                    <p className="description"><FormattedMessage {...AGE_RANGE_DESCRIPTION_MESSAGES[ageRange]} /></p>
                  </div>

                  <div className="buttons-count">
                    <Button
                      shape="circle"
                      icon={<MinusOutlined />}
                      style={{ color: '#e1e7e6', marginLeft: 'auto', marginRight: 8 }}
                      onClick={() => this.handleChange(ageRange, false)}
                    />

                    <span style={{ fontWeight: 600, fontSize: 16 }}>
                      <Label text={value[ageRange]} />
                    </span>

                    <Button
                      shape="circle"
                      icon={<PlusOutlined />}
                      style={{ color: '#e1e7e6', marginLeft: 8 }}
                      onClick={() => this.handleChange(ageRange, true)}
                    />
                  </div>
                </Form.Item>

                {
                  ageRange !== 'senior' ? (
                    <Divider />
                  ) : null
                }
              </div>
            ))
            : null
        }
      </>
    );

    return (
      <Form.Item
        required={required}
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        {...errorProps(errors, `${name}.infant`)}
        {...errorProps(errors, `${name}.youth`)}
        {...errorProps(errors, `${name}.adult`)}
        {...errorProps(errors, `${name}.senior`)}
        {...errorProps(errors, name)}
      >
        <Popover
          content={content}
          trigger="click"
          placement="bottomLeft"
          {...otherProps}
        >
          <Button className={style.guestCountButton} size="large">
            <FormattedMessage defaultMessage="{guests} {guests, plural, one {Guest} other {Guests}}{infants, plural, =0 {} one {, {infants} Infant} other {, {infants} Infants} }" values={{ guests: (value.youth + value.adult + value.senior), infants: value.infant }} />
          </Button>
        </Popover>
      </Form.Item>
    );
  }
}

export default GuestCount;
