import React, {
  useContext, useState, useEffect, useCallback,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarOutlined } from '@ant-design/icons';
import {
  faTachometerAltFast, faBookOpen, faBan, faStore,
  faUser, faUserSlash, faChartPieAlt, faHeadset,
  faCog, faExchange, faArrowLeft, faUsdCircle,
  faMapSigns, faGameBoardAlt, faBorderAll,
} from '@fortawesome/pro-regular-svg-icons';
import NavLink from '../../common/components/NavLink';
import style from './Nav.module.less';
import { withClient } from '../contexts/ClientContext';
import { clientPortalRoute } from '../helpers/clientLinkHelper';
import { AbilityContext } from '../../common/contexts/AbilityContext';
import getCampSlug from '../../../utils/getCampSlug';
import { isImpersonating } from '../../../utils/impersonateClient';
import { getActiveCampId } from '../utils/activeCamp';
import useBreakpoints from '../../common/hooks/useBreakpoints';

const { SubMenu } = Menu;

const ClientPortalNavLink = ({ route, externalLink, ...otherProps }) => {
  const campId = getActiveCampId();
  // If there is no camp, render nothing
  if (!campId) {
    return null;
  }
  let newRoute = route;
  if (!externalLink) {
    newRoute = clientPortalRoute(route);
  }

  return <NavLink route={newRoute} externalLink={externalLink} {...otherProps} />;
};

const MENUS = {
  MAIN: 'main',
  SETTINGS: 'settings',
  USERS: 'users',
  CAMP_INFO: 'camp_info',
  FINANCIAL: 'financial',
  SITES: 'sites',
  RULES: 'rules',
  CAMPS: 'camps',
  EVENTS: 'events',
};

const MENU_ROUTES = {
  '/': {
    menu: MENUS.MAIN,
  },
  '/bookings': {
    menu: MENUS.MAIN,
  },
  '/seasonal-bookings': {
    menu: MENUS.MAIN,
  },
  '/events': {
    menu: MENUS.MAIN,
    subMenu: MENUS.EVENTS,
  },
  '/event-registrations': {
    menu: MENUS.MAIN,
    subMenu: MENUS.EVENTS,
  },
  '/site-blocks': {
    menu: MENUS.MAIN,
  },
  '/users': {
    menu: MENUS.MAIN,
    subMenu: MENUS.USERS,
  },
  '/guest-users': {
    menu: MENUS.MAIN,
    subMenu: MENUS.USERS,
  },
  '/deny-list': {
    menu: MENUS.MAIN,
    subMenu: MENUS.USERS,
  },
  '/waitlist': {
    menu: MENUS.MAIN,
    subMenu: MENUS.USERS,
  },
  '/sales': {
    menu: MENUS.MAIN,
  },
  '/reports': {
    menu: MENUS.MAIN,
  },
  '/camp-settings': {
    menu: MENUS.SETTINGS,
  },
  '/info': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.CAMP_INFO,
  },
  '/settings': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.CAMP_INFO,
  },
  '/live-status': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.CAMP_INFO,
  },
  '/map': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.CAMP_INFO,
  },
  '/contacts': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.CAMP_INFO,
  },
  '/attractions': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.CAMP_INFO,
  },
  '/price-models': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.FINANCIAL,
  },
  '/price-thresholds': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.FINANCIAL,
  },
  '/taxes': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.FINANCIAL,
  },
  '/seasons': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.FINANCIAL,
  },
  '/promo-codes': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.FINANCIAL,
  },
  '/sites': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.SITES,
  },
  '/site-upload': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.SITES,
  },
  '/site-types': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.SITES,
  },
  '/site-templates': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.SITES,
  },
  '/seasonal-settings': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.SITES,
  },
  '/products': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.RULES,
  },
  '/fees': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.RULES,
  },
  '/refund-policies': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.RULES,
  },
  '/long-weekend-rules': {
    menu: MENUS.SETTINGS,
    subMenu: MENUS.RULES,
  },
};

const Nav = ({
  location,
  client,
  camp,
  switchCamp,
  toggleCollapse,
}) => {
  const [activeMenu, setActiveMenu] = useState('main');
  const [routeKey, setRouteKey] = useState('/');
  const [openKeys, setOpenKeys] = useState([]);

  const selectedCamp = getCampSlug(camp);

  const ability = useContext(AbilityContext);

  const usersAllowed = ability && ability.can('read', 'User');

  const eventsAllowed = ability && ability.can('read', 'Event');

  const infoAllowed = ability && ability.can('read', 'Camp', 'info');

  const financialAllowed = ability && (ability.can('read', 'PriceModel')
      || ability.can('read', 'PriceThreshold')
      || ability.can('read', 'Tax')
      || ability.can('read', 'Season')
      || ability.can('read', 'PromoCode'));

  const sitesAllowed = ability && (ability.can('read', 'Site')
      || ability.can('read', 'SiteType')
      || ability.can('read', 'SiteTemplate')
      || ability.can('read', 'AvailabilityCalendar'));

  const rulesAllowed = ability && (ability.can('read', 'Product')
    || ability.can('read', 'Fee')
    || ability.can('update', 'camp', 'refundPolicies')
    || ability.can('read', 'LongWeekendRule'));

  const settingsAllowed = infoAllowed || financialAllowed || sitesAllowed || rulesAllowed;

  const allowedMenus = {
    usersAllowed,
    infoAllowed,
    financialAllowed,
    sitesAllowed,
    rulesAllowed,
    settingsAllowed,
    eventsAllowed,
  };

  const { lg } = useBreakpoints();

  // Make sure that the subMenu which contains this route is open
  const handleOpenChange = useCallback((keys) => {
    if (!keys.includes(MENU_ROUTES[routeKey].subMenu) && lg) {
      setOpenKeys([...keys, MENU_ROUTES[routeKey].subMenu]);
    } else {
      setOpenKeys(keys);
    }
  }, [routeKey]);

  const handleMainMenuSelect = useCallback(({ key }) => {
    if (key === '/camp-settings') {
      handleOpenChange([]);
    }
  }, [handleOpenChange]);

  const handleSettingsMenuSelect = useCallback(({ key }) => {
    if (key === '/') {
      handleOpenChange([]);
    }
  }, [handleOpenChange]);

  useEffect(() => {
    // Get the specific route key for the page, inluding the starting '/',
    // but excluding trailing slashes or anything after a ?
    const route = (location.pathname.split(/\/client\/[^/]+/)[1] || '/').match(/\/[^/?]*/)[0];
    // If it's a valid route
    if (MENU_ROUTES[route]) {
      // set which menu should be showing
      setActiveMenu(MENU_ROUTES[route].menu);
      // Set the key to show which sub menus should be open and which menu item should be selected
      setRouteKey(route);

      handleOpenChange(openKeys);
    }
  }, [location, handleOpenChange, openKeys]);

  const impersonating = isImpersonating();
  return (
    <>
      <Menu
        className={style.navMenu}
        // Selected key is the route after /client/*campId*/ including the starting '/'
        selectedKeys={[routeKey || '/', selectedCamp]}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        onSelect={handleMainMenuSelect}
        mode="inline"
        onClick={toggleCollapse}
        hidden={activeMenu !== MENUS.MAIN}
        key={MENUS.MAIN}
        forceSubMenuRender
      >
        <ClientPortalNavLink key="/" route="/">
          <span className={style.icon}>
            <FontAwesomeIcon icon={faTachometerAltFast} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Dashboard" />
          </span>
        </ClientPortalNavLink>

        <ClientPortalNavLink key="/bookings" route="/bookings" can={{ I: 'read', a: 'Booking' }}>
          <span className={style.icon}>
            <FontAwesomeIcon icon={faBookOpen} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Bookings" />
          </span>
        </ClientPortalNavLink>
        {
          camp?.seasonalSettings ? (
            <ClientPortalNavLink key="/seasonal-bookings" route="/seasonal-bookings" can={{ I: 'read', a: 'Booking' }}>
              <span className={style.icon}>
                <FontAwesomeIcon icon={faBookOpen} />
              </span>
              <span className={style.navTitle}>
                <FormattedMessage defaultMessage="Seasonal Bookings" />
              </span>
            </ClientPortalNavLink>
          ) : null
        }

        <SubMenu
          hidden={!allowedMenus.eventsAllowed}
          key={MENUS.EVENTS}
          title={
            (
              <div className={MENU_ROUTES[routeKey].subMenu === MENUS.EVENTS ? 'ant-menu-submenu-selected' : null}>
                <span className={style.icon}>
                  <CalendarOutlined />
                </span>
                <span className={style.navTitle}>
                  <FormattedMessage defaultMessage="Events" />
                </span>
              </div>
            )
          }
        >
          <ClientPortalNavLink key="/events" route="/events">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Campground Events" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/event-registrations" route="/event-registrations">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Registrations" />
            </span>
          </ClientPortalNavLink>
        </SubMenu>

        <ClientPortalNavLink key="/site-blocks" route="/site-blocks" can={{ I: 'read', a: 'SiteBlock' }}>
          <span className={style.icon}>
            <FontAwesomeIcon icon={faBan} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Block Sites" />
          </span>
        </ClientPortalNavLink>

        <SubMenu
          hidden={!allowedMenus.usersAllowed}
          key={MENUS.USERS}
          title={
            (
              <div className={MENU_ROUTES[routeKey].subMenu === MENUS.USERS ? 'ant-menu-submenu-selected' : null}>
                <span className={style.icon}>
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <span className={style.navTitle}>
                  <FormattedMessage defaultMessage="Users" />
                </span>
              </div>
            )
          }
        >
          <ClientPortalNavLink key="/users" route="/users" can={{ I: 'read', a: 'User' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Staff" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/guest-users" route="/guest-users" can={{ I: 'read', a: 'User' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Guests" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/deny-list" route="/deny-list" can={{ I: 'read', a: 'DenyListEntry' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Denylist" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/waitlist" route="/waitlist" can={{ I: 'read', a: 'Waitlist' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Waitlist" />
            </span>
          </ClientPortalNavLink>
        </SubMenu>

        <ClientPortalNavLink key="/sales" route="/sales" can={{ I: 'read', a: 'Sale' }}>
          <span className={style.icon}>
            <FontAwesomeIcon icon={faStore} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Point of Sale" />
          </span>
        </ClientPortalNavLink>

        <ClientPortalNavLink key="/reports" route="/reports" can={{ I: 'read', a: 'Report' }}>
          <span className={style.icon}>
            <FontAwesomeIcon icon={faChartPieAlt} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Reports" />
          </span>
        </ClientPortalNavLink>

        <ClientPortalNavLink key="/camp-settings" route="/camp-settings" hidden={!allowedMenus.settingsAllowed}>
          <span className={style.icon}>
            <FontAwesomeIcon icon={faCog} />
          </span>
          <span className={`${style.settings} ${style.navTitle}`}>
            <FormattedMessage defaultMessage="Settings" />
          </span>
        </ClientPortalNavLink>

        <ClientPortalNavLink key="/support" externalLink route="https://support.letscamp.ca/campground-support/">
          <span className={style.icon}>
            <FontAwesomeIcon icon={faHeadset} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Support" />
          </span>
        </ClientPortalNavLink>

        {
          client && client.camps && client.camps.length > 1
            ? (
              <SubMenu
                key={MENUS.CAMPS}
                title={
                  (
                    <>
                      <span className={style.icon}>
                        <FontAwesomeIcon icon={faExchange} />
                      </span>
                      <span className={style.navTitle}>
                        <FormattedMessage defaultMessage="Change Camp" />
                      </span>
                    </>
                  )
                }
              >
                {
                  client.camps.map((clientCamp) => {
                    if (ability.can('read', clientCamp)) {
                      return (
                        <Menu.Item
                          key={clientCamp._id}
                          onClick={() => switchCamp(clientCamp._id)}
                          className={`${style.navTitle} ${style.campMenuItem}`}
                        >
                          {clientCamp.name}
                        </Menu.Item>
                      );
                    }
                    return null;
                  })
                }
              </SubMenu>
            )
            : null
        }

        {
          impersonating
            ? (
              <Menu.Item key="impersonating" className={style.navTitle}>
                <Link to={`/admin/camps/${selectedCamp || ''}`}>
                  <span className={style.icon}>
                    <FontAwesomeIcon icon={faUserSlash} />
                  </span>
                  <span>
                    <FormattedMessage defaultMessage="Stop Impersonating" />
                  </span>
                </Link>
              </Menu.Item>
            )
            : null
        }
      </Menu>
      <Menu
        className={style.navMenu}
        // Selected key is the route after /client/*campId*/ including the starting '/'
        selectedKeys={[routeKey || '/camp-settings', selectedCamp]}
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        onSelect={handleSettingsMenuSelect}
        mode="inline"
        onClick={toggleCollapse}
        hidden={activeMenu !== MENUS.SETTINGS}
        key={MENUS.SETTINGS}
      >
        <ClientPortalNavLink key="/" route="/" className={style.greenButton}>
          <span className={style.icon}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Exit Settings" />
          </span>
        </ClientPortalNavLink>

        <ClientPortalNavLink key="/camp-settings" route="/camp-settings">
          <span className={style.icon}>
            <FontAwesomeIcon icon={faBorderAll} />
          </span>
          <span className={style.navTitle}>
            <FormattedMessage defaultMessage="Settings" />
          </span>
        </ClientPortalNavLink>

        <SubMenu
          hidden={!allowedMenus.infoAllowed}
          key={MENUS.CAMP_INFO}
          title={
            (
              <div className={MENU_ROUTES[routeKey].subMenu === MENUS.CAMP_INFO ? 'ant-menu-submenu-selected' : null}>
                <span className={style.icon}>
                  <FontAwesomeIcon icon={faCog} />
                </span>
                <span className={style.navTitle}>
                  <FormattedMessage defaultMessage="General Settings" />
                </span>
              </div>
            )
          }
        >
          <ClientPortalNavLink key="/live-status" route="/live-status">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Camp Live Status" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/info" route="/info">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Camp Profile" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/settings" route="/settings">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Camp Settings" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/map" route="/map">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Camp Map" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/contacts" route="/contacts">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Camp Contacts" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/attractions" route="/attractions">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Nearby Attractions" />
            </span>
          </ClientPortalNavLink>
        </SubMenu>

        <SubMenu
          hidden={!allowedMenus.financialAllowed}
          key={MENUS.FINANCIAL}
          title={
            (
              <div className={MENU_ROUTES[routeKey].subMenu === MENUS.FINANCIAL ? 'ant-menu-submenu-selected' : null}>
                <span className={style.icon}>
                  <FontAwesomeIcon icon={faUsdCircle} />
                </span>
                <span className={style.navTitle}>
                  <FormattedMessage defaultMessage="Financial Settings" />
                </span>
              </div>
            )
          }
        >
          <ClientPortalNavLink key="/price-models" route="/price-models" can={{ I: 'read', a: 'PriceModel' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Pricing Models" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/price-thresholds" route="/price-thresholds" can={{ I: 'read', a: 'PriceThreshold' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Price Thresholds" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/taxes" route="/taxes" can={{ I: 'read', a: 'Tax' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Taxes" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/seasons" route="/seasons" can={{ I: 'read', a: 'Season' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Seasons & Pricing" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/promo-codes" route="/promo-codes" can={{ I: 'read', a: 'PromoCode' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Promo Codes" />
            </span>
          </ClientPortalNavLink>
        </SubMenu>

        <SubMenu
          hidden={!allowedMenus.sitesAllowed}
          key={MENUS.SITES}
          title={
            (
              <div className={MENU_ROUTES[routeKey].subMenu === MENUS.SITES ? 'ant-menu-submenu-selected' : null}>
                <span className={style.icon}>
                  <FontAwesomeIcon icon={faMapSigns} />
                </span>
                <span className={style.navTitle}>
                  <FormattedMessage defaultMessage="Site Settings" />
                </span>
              </div>
            )
          }
        >
          <ClientPortalNavLink key="/sites" route="/sites" can={{ I: 'read', a: 'Site' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Sites" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/site-types" route="/site-types" can={{ I: 'read', a: 'SiteType' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Site Types" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/site-templates" route="/site-templates" can={{ I: 'read', a: 'SiteTemplate' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Templates" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/site-upload" route="/site-upload" can={{ I: 'create', a: 'Site' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Bulk Site Upload" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/seasonal-settings" route="/seasonal-settings/edit">
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Seasonal Site Settings" />
            </span>
          </ClientPortalNavLink>
        </SubMenu>

        <SubMenu
          hidden={!allowedMenus.rulesAllowed}
          key={MENUS.RULES}
          title={
            (
              <div className={MENU_ROUTES[routeKey].subMenu === MENUS.RULES ? 'ant-menu-submenu-selected' : null}>
                <span className={style.icon}>
                  <FontAwesomeIcon icon={faGameBoardAlt} />
                </span>
                <span className={style.navTitle}>
                  <FormattedMessage defaultMessage="Rules, Products, & Fees" />
                </span>
              </div>
            )
          }
        >
          <ClientPortalNavLink key="/products" route="/products" can={{ I: 'read', a: 'Product' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Products" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/fees" route="/fees" can={{ I: 'read', a: 'Fee' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Fees" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/refund-policies" route="/refund-policies" can={{ I: 'update', a: 'Camp', field: 'refundPolicies' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Refund Policies" />
            </span>
          </ClientPortalNavLink>

          <ClientPortalNavLink key="/long-weekend-rules" route="/long-weekend-rules" can={{ I: 'read', a: 'LongWeekendRule' }}>
            <span className={style.navTitle}>
              <FormattedMessage defaultMessage="Long Weekend Rules" />
            </span>
          </ClientPortalNavLink>
        </SubMenu>

        {
          impersonating
            ? (
              <Menu.Item key="impersonating">
                <Link to={`/admin/camps/${selectedCamp || ''}`}>
                  <span className={style.icon}>
                    <FontAwesomeIcon icon={faUserSlash} />
                  </span>
                  <span className={style.navTitle}>
                    <FormattedMessage defaultMessage="Stop Impersonating" />
                  </span>
                </Link>
              </Menu.Item>
            )
            : null
        }
      </Menu>
    </>
  );
};

export default withClient(withRouter(Nav));
