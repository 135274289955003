import React, { Component } from 'react';
import { Form } from 'antd';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { errorProps } from '../../../../utils/errors';
import style from './TextEditor.module.less';
import inputStyle from './Input.module.less';
import Label from './Label';
import { DEFAULT_MARKDOWN_FORMATTING_OPTIONS } from '../../../../config/constants/constants';
import markdown from '../../../../utils/markdown';

class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.formattingOptions = props.formattingOptions || DEFAULT_MARKDOWN_FORMATTING_OPTIONS;

    this.mdParser = markdown(this.formattingOptions);

    this.state = {
      selectedTab: 'write',
    };
  }

  // Handle change for text input
  handleChange(value) {
    const { name, onChange } = this.props;

    onChange(name, value);
  }

  handleTabChange(tab) {
    this.setState({ selectedTab: tab });
  }

  render() {
    const {
      name,
      value,
      label,
      errors,
      onChange,
      tooltip,
      additionalText,
      disabled,
      ...otherProps
    } = this.props;

    const { selectedTab } = this.state;

    return (
      <Form.Item
        label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
        {...errorProps(errors, name)}
      >
        {
          additionalText ? (
            <div className={inputStyle.additionalText}>{additionalText}</div>
          ) : null
        }
        <div className={[style.textEditor, disabled ? style.disabled : ''].join(' ')}>
          <ReactMde
            name={name}
            value={value}
            selectedTab={selectedTab}
            onTabChange={this.handleTabChange}
            onChange={this.handleChange}
            generateMarkdownPreview={
              (text) => Promise.resolve(this.mdParser.render(text || ''))
            }
            toolbarCommands={this.formattingOptions}
            {...otherProps}
          />
        </div>
      </Form.Item>
    );
  }
}

export default TextEditor;
