import React, { useEffect, useState, useRef } from 'react';
import { Select as AntdSelect } from 'antd';
import _ from 'lodash';

const SearchableSelect = (props) => {
  const {
    children,
    value,
    onChange,
    ...otherProps
  } = props;

  // store a reference to the current value
  const prevValueRef = useRef();
  useEffect(() => {
    prevValueRef.current = value;
  });
  const prevValue = prevValueRef.current;

  // Handle change for select input
  const handleChange = (newValue) => {
    let realNewValue;
    if (Array.isArray(newValue)) {
      realNewValue = newValue.map((val) => val.value);
    } else {
      realNewValue = newValue ? newValue.value : null;
    }

    onChange(realNewValue);
  };

  const [internalValue, setInternalValue] = useState();

  useEffect(() => {
    if (_.isEqual(prevValue, value)) {
      return;
    }

    if (value == null) {
      setInternalValue(value);
      return;
    }

    let options = children;
    if (!Array.isArray(options)) {
      options = [options];
    }

    const valueForOption = (val) => {
      const existingOption = options.find((option) => (
        option ? option.props.value === val : false
      ));
      return {
        key: val,
        label: existingOption ? existingOption.props.children : val,
      };
    };

    let newInternalValue;
    if (Array.isArray(value)) {
      newInternalValue = value.map((val) => {
        const existingInternalValue = internalValue
          ? internalValue.find((internalVal) => internalVal.key === val)
          : null;
        if (existingInternalValue) {
          return existingInternalValue;
        }
        return valueForOption(val);
      });
    } else {
      newInternalValue = valueForOption(value);
    }

    setInternalValue(newInternalValue);
  }, [value, children, internalValue, prevValue]);

  return (
    <AntdSelect
      // Note: Placeholder will only appear if value is undefined
      value={internalValue}
      onChange={handleChange}
      {...otherProps}
      labelInValue
    >
      {children}
    </AntdSelect>
  );
};

export default SearchableSelect;
