import React, {
  useEffect, useState, useRef, useContext, useMemo,
} from 'react';
import { differenceInMilliseconds, parseISO, addMilliseconds } from 'date-fns';
import axios from 'axios';
import UserContext from './UserContext';

const CartContext = React.createContext();

/**
 * This class serves as a wrapper for the CartContext.Provider.
 * this will provide all consumers with the current cart details
 */
export const CartProvider = ({ children }) => {
  const [details, setDetails] = useState({});
  const [times, setTimes] = useState();
  const detailsRef = useRef(false);

  const { user, loaded: userLoaded } = useContext(UserContext);

  useEffect(() => {
    let cartInterceptor;

    if (user && userLoaded) {
      cartInterceptor = axios.interceptors.response.use(async (res) => {
        if (res.headers['x-cart-updated']) {
          await axios.get('/cart/details')
            .then((response) => {
              setDetails(response.data.cart);
              setTimes({ server: parseISO(response.data.time), client: new Date() });
            })
            .catch(() => setDetails((d) => ({ ...d, error: true })));
        }
        return res;
      });

      if (!detailsRef.current) {
        axios.get('/cart/details')
          .then((response) => {
            setDetails(response.data.cart);
            setTimes({ server: parseISO(response.data.time), client: new Date() });
          })
          .catch(() => setDetails((d) => ({ ...d, error: true })))
          .finally(() => { detailsRef.current = true; });
      }
    }
    return () => {
      if (cartInterceptor) {
        axios.interceptors.response.eject(cartInterceptor);
      }
    };
  }, [user, userLoaded]);

  const { lockedUntil } = (details ?? {});

  const effectiveLockedUntil = useMemo(() => {
    if (!lockedUntil || !times) {
      return undefined;
    }
    const offset = differenceInMilliseconds(times.client, times.server);
    return addMilliseconds(parseISO(lockedUntil), offset);
  }, [times, lockedUntil]);

  // Clear the details when the timer runs out
  useEffect(() => {
    let timeout;

    if (effectiveLockedUntil) {
      if (effectiveLockedUntil > new Date()) {
        timeout = setTimeout(() => {
          setDetails({});
        }, differenceInMilliseconds(effectiveLockedUntil, new Date()));
      }
    }
    return () => clearTimeout(timeout);
  }, [effectiveLockedUntil]);

  return (
    <CartContext.Provider
      value={
        {
          cart: details,
          effectiveLockedUntil,
        }
      }
    >
      {children}
    </CartContext.Provider>
  );
};

export const CartConsumer = CartContext.Consumer;

export default CartContext;
