import React, { useState, useCallback } from 'react';
import { Form } from 'antd';
import { CardCvcElement } from '@stripe/react-stripe-js';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';
import { INPUT_OPTIONS } from './CreditCardInput';

const SecurityCodeInput = (props) => {
  const {
    name,
    label,
    errors,
    required,
    className,
    tooltip,
  } = props;

  const [error, setError] = useState(null);

  const handleChange = useCallback((event) => {
    if (event.error) {
      setError({ [name]: { message: event.error.message } });
    } else {
      setError(null);
    }
  }, [name]);

  return (
    <Form.Item
      label={label ? <Label text={label} tooltip={tooltip} /> : undefined}
      required={required}
      {...errorProps(errors, name)}
      {...errorProps(error, name)}
    >
      <CardCvcElement className={`${className || ''} security-code-input`} options={INPUT_OPTIONS} onChange={handleChange} />
    </Form.Item>
  );
};

export default SecurityCodeInput;
