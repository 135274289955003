import React, { Component } from 'react';
import { Checkbox as AntdCheckbox, Form } from 'antd';
import { errorProps } from '../../../../utils/errors';
import Label from './Label';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // Handle change for checkbox input
  handleChange(event) {
    const { name, checked } = event.target;
    const { onChange } = this.props;

    onChange(name, checked);
  }

  render() {
    const {
      name,
      checked,
      label,
      errors,
      onChange,
      tooltip,
      ...otherProps
    } = this.props;

    return (
      <Form.Item {...errorProps(errors, name)}>
        <AntdCheckbox
          name={name}
          checked={checked || false}
          onChange={this.handleChange}
          {...otherProps}
        >
          {
            label ? (
              <Label text={label} tooltip={tooltip} />
            ) : null
          }
        </AntdCheckbox>
      </Form.Item>
    );
  }
}

export default Checkbox;
