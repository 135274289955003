import React, { useContext, useState } from 'react';
import {
  Button, Modal, Typography, Form, Row, Col,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import logoColorBlack from '../../../images/logo-color-black.svg';
import style from './CookieConsentModal.module.less';
import { Switch } from './inputs';
import CookieConsentContext from '../contexts/CookieConsentContext';
import LabelColumn from './inputs/helpers/LabelColumn';

const { Title } = Typography;

const CookieConsentModal = (props) => {
  const { showModal, setShowModal } = props;
  const { setConsent, cookieConsent } = useContext(CookieConsentContext);
  const [acceptAnalytic, setAcceptAnalytic] = useState(Boolean(cookieConsent?.analyticalConsent));
  return (
    <Modal
      title={<img src={logoColorBlack} alt="Let's Camp" height={30} />}
      visible={showModal}
      footer={
        [
          <Button
            onClick={() => { setConsent(acceptAnalytic); setShowModal(false); }}
            className={style.applyButton}
            type="primary"
            block
            key={1}
          >
            <FormattedMessage defaultMessage="Apply my Choices" />
          </Button>,
        ]
      }
      onCancel={() => setShowModal(false)}
    >
      <Title level={4}>
        <FormattedMessage defaultMessage="Customize Cookies" />
      </Title>
      <Form.Item
        className={style.switch}
      >
        <Row justify="space-between">
          <LabelColumn label={<FormattedMessage defaultMessage="Strictly Necessary Cookies" />} />
          <Col>
            <span className={style.alwaysOn}>
              <FormattedMessage defaultMessage="Always Active" />
            </span>
          </Col>
        </Row>
      </Form.Item>
      <p>
        <FormattedMessage defaultMessage="These cookies are required for our website to work and include functions like staying logged in, having data like dates and unit type go from one step in the booking process to the next, and remembering which site you hit &quot;book this site&quot; on when you reach the payment screen." />
      </p>
      <Switch
        label={<FormattedMessage defaultMessage="Analytical Cookies" />}
        labelPosition="left"
        onChange={() => setAcceptAnalytic(!acceptAnalytic)}
        value={acceptAnalytic}
      />
      <p>
        <FormattedMessage defaultMessage="These cookies include 3rd-party services like Google Analytics, Facebook Pixel, and Hotjar. We use these to track important information like how long it takes to make a booking and which devices our users are accessing from so we can continue to make informed improvements to our software experience." />
      </p>
    </Modal>
  );
};

export default CookieConsentModal;
