import { useEffect } from 'react';
import style from './useNoScroll.module.less';

/**
 * Prevents the body element from scrolling while active
 */
const useNoScroll = (active) => {
  useEffect(
    () => {
      const { body } = document;
      if (active) {
        body.classList.add(style.noScroll);
      } else {
        body.classList.remove(style.noScroll);
      }
    }, [active],
  );
};

export default useNoScroll;
