import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Tooltip,
  Divider,
} from 'antd';
import {
  FaTint, FaToilet, FaPlug, FaWheelchair, FaArrowsAltH,
  FaPaw,
} from 'react-icons/fa';
import style from './SiteAttributes.module.less';
import {
  SITE_WATER_MESSAGES, SITE_SEWER_MESSAGES, SITE_PULL_THROUGH_MESSAGES,
  SITE_ACCESSIBLE_MESSAGES,
  SITE_ALLOW_PETS_MESSAGES,
} from '../../../../config/constants/site';

const SiteAttributes = ({ site, ...otherProps }) => (
  <div style={{ minWidth: 140 }} {...otherProps}>
    <Tooltip
      title={
        site.electrical && site.electrical.length
          ? (
            <FormattedMessage defaultMessage="{amps} amps" values={{ amps: site.electrical.sort().join(', ') }} />
          ) : <FormattedMessage defaultMessage="No Electrical Service" />
      }
    >
      <span>
        <FaPlug color="black" className={`${site.electrical?.length ? '' : style.disabled}`} />
      </span>
    </Tooltip>
    <Divider type="vertical" />

    <Tooltip title={<FormattedMessage {...SITE_WATER_MESSAGES[Boolean(site.water)]} />}>
      <span>
        <FaTint color="black" className={`${site.water ? '' : style.disabled}`} />
      </span>
    </Tooltip>
    <Divider type="vertical" />

    <Tooltip title={<FormattedMessage {...SITE_SEWER_MESSAGES[Boolean(site.sewer)]} />}>
      <span>
        <FaToilet color="black" className={`${site.sewer ? '' : style.disabled}`} />
      </span>
    </Tooltip>
    <Divider type="vertical" />

    <Tooltip
      title={<FormattedMessage {...SITE_PULL_THROUGH_MESSAGES[Boolean(site.pullThrough)]} />}
    >
      <span>
        <FaArrowsAltH color="black" className={`${site.pullThrough ? '' : style.disabled}`} />
      </span>
    </Tooltip>
    <Divider type="vertical" />

    <Tooltip title={<FormattedMessage {...SITE_ACCESSIBLE_MESSAGES[Boolean(site.accessible)]} />}>
      <span>
        <FaWheelchair color="black" className={`${site.accessible ? '' : style.disabled}`} />
      </span>
    </Tooltip>

    <Divider type="vertical" />

    <Tooltip title={<FormattedMessage {...SITE_ALLOW_PETS_MESSAGES[Boolean(site.allowPets)]} />}>
      <span>
        <FaPaw color="black" className={`${site.allowPets ? '' : style.disabled}`} />
      </span>
    </Tooltip>
  </div>
);

export default SiteAttributes;
