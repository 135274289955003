import countries from 'i18n-iso-countries';
import langSupport from '../countryLanguageSupport';
import { appLocale } from '../setupConfig';

countries.registerLocale(langSupport);

const countrySynonyms = {
  en: {
    US: ['america', 'new world', 'united states', 'us', 'us of a', 'usa'],
  },
  fr: {
    US: ['états-unis', 'é-ua', 'é-u', 'usa'],
  },
};

export const GETALPHA2CODE = (country) => {
  // Set country to lowercase and remove any periods
  const simpleCountry = country.toLowerCase().replace(/\./g, '');
  // Check if a synonym for the country entered exists
  let countryCode = null;

  if (countrySynonyms[appLocale.code]) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(countrySynonyms[appLocale.code])) {
      if (value.includes(simpleCountry)) {
        countryCode = key;
      }
    }
  }

  if (!countryCode) {
    return countries.getAlpha2Code(country, appLocale.code);
  }

  return countryCode;
};
export const COUNTRIES = countries.getNames(appLocale.code);
export const COUNTRY_CODE_CANADA = 'CA';
export const COUNTRY_CODE_US = 'US';
