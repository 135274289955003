import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'antd';
import { UserOutlined, TeamOutlined, PieChartOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import {
  FaCampground,
  FaLayerGroup,
  FaHeadset,
} from 'react-icons/fa';
import NavLink from '../../common/components/NavLink';
import style from './Nav.module.less';

const { SubMenu } = Menu;

const Nav = ({ location, toggleCollapse }) => (
  <Menu
    className={style.navMenu}
    selectedKeys={[location.pathname]}
    mode="inline"
    onClick={toggleCollapse}
  >
    <NavLink key="/admin/users" route="/admin/users" can={{ I: 'read', a: 'User' }}>
      <UserOutlined />
      <span>
        <FormattedMessage defaultMessage="Users" />
      </span>
    </NavLink>

    <NavLink key="/admin/clients" route="/admin/clients" can={{ I: 'read', a: 'Client' }}>
      <TeamOutlined />
      <span>
        <FormattedMessage defaultMessage="Clients" />
      </span>
    </NavLink>

    <NavLink key="/admin/camps" route="/admin/camps" can={{ I: 'read', a: 'Camp' }}>
      <FaCampground />
      <span>
        <FormattedMessage defaultMessage="Camps" />
      </span>
    </NavLink>

    <NavLink key="/admin/tiers" route="/admin/tiers" can={{ I: 'read', a: 'Tier' }}>
      <FaLayerGroup />
      <span>
        <FormattedMessage defaultMessage="Tiers" />
      </span>
    </NavLink>

    <SubMenu
      key="reports"
      title={
          (
            <>
              <PieChartOutlined />
              <span>
                <FormattedMessage defaultMessage="Reports" />
              </span>
            </>
          )
        }
    >
      <NavLink key="/admin/booking-fee-report" route="/admin/booking-fee-report" can={{ I: 'read', a: 'Report' }}>
        <FormattedMessage defaultMessage="Booking Fee Report" />
      </NavLink>

      <NavLink key="/admin/booking-fee-report?reportType=detailedBookingFeeReport" route="/admin/booking-fee-report?reportType=detailedBookingFeeReport" can={{ I: 'read', a: 'Report' }}>
        <FormattedMessage defaultMessage="Detailed Booking Fee Report" />
      </NavLink>
    </SubMenu>

    <SubMenu
      key="support"
      title={
          (
            <>
              <FaHeadset />
              <span>
                <FormattedMessage defaultMessage="Support" />
              </span>
            </>
          )
        }
    >
      <NavLink key="/admin/booking-lookup" route="/admin/booking-lookup" can={{ I: 'read', a: 'Booking' }}>
        <FormattedMessage defaultMessage="Booking Lookup" />
      </NavLink>
    </SubMenu>
  </Menu>
);

export default withRouter(Nav);
